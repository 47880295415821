const API_VER = 'v1.0'
export const AuthEndpoints = {
    AUTH_USER_LOGIN: `/oauth2/api/${API_VER}/token`,
    AUTH_USER_URI: `/auth/api/${API_VER}/users/`,
    AUTH_USER_SIGN_UP: `/auth/api/${API_VER}/users/sign_up/`,
    AUTH_USER_FORGOT_PWD: `/auth/api/${API_VER}/users/forgot_password/`,
    AUTH_USER_INFO_URI: `/auth/api/${API_VER}/users/info/`,
    AUTH_USER_SUPPORT_FEEDBACK: `/auth/api/${API_VER}/users/support_feedback/`,
    AUTH_USER_FIRST_LOGIN: `/auth/api/${API_VER}/users/first_login/`,
    AUTH_USER_FIRST_LOGIN_TIMESHEET: `/auth/api/${API_VER}/users/first_login_by_timesheet/`,
    AUTH_USER_DELETE_URI: `/auth/api/${API_VER}/users/delete_account/`,
    AUTH_UPDATE_USER_INFO_URI: `/auth/api/${API_VER}/users/update_info/`,
    AUTH_USER_RESET_PASSWORD: `/auth/api/${API_VER}/users/reset_password/`,
    AUTH_USER_COMPANY: `/auth/api/${API_VER}/companies/`,
    AUTH_USER_COMPANY_CREATION: `/auth/api/${API_VER}/companies/sign_up/`,
    AUTH_USER_COMPANY_UPDATE: `/auth/api/${API_VER}/companies/update_info/`,
    AUTH_USER_COMPANY_INFO_URI: `/auth/api/${API_VER}/companies/info/`,
    AUTH_USER_SELECT_COMPANY: `/auth/api/${API_VER}/companies/switch_company/`,
}
