import React, {Fragment, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogTitle, Grid, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {Delete} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import EmployeeService from "@/services/hr/EmployeeService";
import {useSnackbar} from "notistack";
import {CancelButton, SaveButton} from "@/components/data-grid/CustomButton";
import SnackbarUtil from "@/utils/SnackbarUtil";

const dialogStyle = {
  '& .MuiPaper-root': {width: '400px', top: '1vh', overflowX: "hidden"},
  '.MuiDialog-paper': {maxWidth: '100vw'},
  overflowX: "hidden",
};

export function DeleteAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("common.Delete Employee")}>
      <IconButton sx={{borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)"}} onClick={() => {
        setInfo(true)
      }}>
        <Delete/>
      </IconButton>
    </Tooltip>
  )
}

export interface EmpDeleteProps {
  empIds: Record<string, any>,
}

const EmpDelete: React.FC<EmpDeleteProps> = (props) => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <DeleteAction setInfo={setInfo}/>
      </Grid>
      <DeleteEmpDialog info={info} setInfo={setInfo} empIds={props.empIds}/>
    </Fragment>
  )
}

const DeleteEmpDialog = ({info, setInfo, empIds}: any) => {
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar();
  const onSubmit = async (event: any) => {
    if (empIds.length == 0) {
      SnackbarUtil.error(t("att.schedule.Select person first"), {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return;
    }
    try {
      const employee_list = empIds.map((emp: any) => {
        EmployeeService.destroy(emp.toString())
      })
      enqueueSnackbar(t("common.success"), {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      });
    } catch (e) {
      console.log("error===", e)
    } finally {
      setInfo(false)
    }
  }
  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      sx={dialogStyle}
    >
      <DialogTitle>
        {t("common.Delete Employee")}
        <IconButton
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <Typography sx={{textAlign: "center"}}>
        {t('common.deleteConfirm')}
      </Typography>
      <Grid style={{'padding': '20px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end">
          <CancelButton onClick={() => {
            setInfo(false)
          }}/>
          <SaveButton onClick={onSubmit} children={t('common.confirm')}/>
        </Stack>
      </Grid>
    </Dialog>
  )
}

export default EmpDelete;
