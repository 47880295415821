import React, {useEffect, useRef, useState, useLayoutEffect, useContext, Fragment} from "react";
import {
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  GridFilterModel,
  GridFilterOperator,
  GridFilterItem,
  GridFilterInputValueProps
} from "@mui/x-data-grid";
import {ATTEndpoints} from "../../../../services/att/endpoints";
import WebURL from "../../../../urls";
import CustomDataGrid, {DataGridRef} from "../../../../components/data-grid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, IconButton,
  TextField
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {useNavigate} from "react-router-dom";
import SnackbarUtil from "@/utils/SnackbarUtil";
import moment from "moment";
import TimecardService from "@/services/att/services/TimecardService";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import {DataGridContext, DataGridDispatchContext} from "@/components/data-grid/DataGridContext";
import ExportAction from "@/components/actions/Export";
import Util from "@/utils/Util";
import lodash from "lodash";
import internal from "stream";

const SUBMIT_FILTER_STROKE_TIME = 500;

const punchRecordStyle = {
  '& .MuiPaper-root': {width: '60vw', top: '-20vh'},
  '.MuiDialog-paper': {maxWidth: '100vw'},
};

function InputNumberInterval(props: GridFilterInputValueProps) {
  const {t} = useTranslation();
  const {item, applyValue} = props;

  const filterTimeout = useRef<any>();
  const [filterValueState, setFilterValueState] = useState<[Date, Date]>([item.value[0], item.value[1]]);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  // useEffect(() => {
  //   const itemValue = item.value ?? [undefined, undefined];
  //   const today = new Date()
  //   itemValue[0]=new Date(today.getFullYear(), today.getMonth(), 1)
  //   setFilterValueState(itemValue);
  // }, [item.value]);

  const updateFilterValue = (lowerBound: Date, upperBounding: Date) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBounding]);

    filterTimeout.current = setTimeout(() => {
      applyValue({...item, value: [lowerBound, upperBounding]});
    }, SUBMIT_FILTER_STROKE_TIME)
  };

  const handleUpperFilterChange = (event: any) => {
    const newUpperBound = event;
    updateFilterValue(filterValueState[0], newUpperBound);
  };
  const handleLowerFilterChange = (event: any) => {
    const newLowerBound = event;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
      }}
    >
      <DatePicker
        label={t("common.Start Date")}
        value={filterValueState[0] || new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
        maxDate={filterValueState[1]}
        inputFormat={"dd-MM-yyyy"}
        onChange={handleLowerFilterChange}
        renderInput={(params) =>
          <TextField
            variant="standard"
            {...params}
          />}
      />
      <DatePicker
        label={t("common.End Date")}
        value={filterValueState[1]}
        minDate={filterValueState[0]}
        inputFormat={"dd-MM-yyyy"}
        onChange={handleUpperFilterChange}
        renderInput={(params) =>
          <TextField
            variant="standard"
            {...params}
          />}
      />
    </Box>
  )
}


const AttendanceReport: React.FC = () => {
  const {t, i18n} = useTranslation();
  const today = new Date()
  const firstDay = new Date()
  firstDay.setDate(1)
  const [exportFormat, setExportFormat] = useState<string>("");
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(0);
  const [start_date, setStartDate] = useState<Date>(firstDay);
  const [end_date, setEndDate] = useState<Date>(new Date());
  const setFilter = (newValue: Record<any, any>) => {
    const {month} = newValue
    if (!month) {
      return
    }
    setFilterParams(newValue)
  }
  const [filterParams, setFilterParams] = useState<Record<any, any>>({
    date_range: [
      firstDay.toISOString().slice(0, 10),
      today.toISOString().slice(0, 10)
    ]
  });
  const [exportFilterParams, setExportFilterParams] = useState<Record<any, any>>({
    date_range: [
      start_date.toISOString().slice(0, 10),
      end_date.toISOString().slice(0, 10)
    ]
  });

  function isValidDate(date: any) {
    return date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 2000
  }

  const attDateOperators: GridFilterOperator[] = [
    {
      label: t("common.Between"),
      value: "between",
      getApplyFilterFn: (filterItem: GridFilterItem, column) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        return ({value}) => {
          return true;
        };
      },
      InputComponent: InputNumberInterval
    },
  ];

  useEffect(() => {
    dataGridRef.current?.refresh();
  }, [i18n.language])

  useEffect(() => {
    if (exportFormat) {
      const export_meta = {
        fields: {
          employee_code: {
            alias: t("common.Person ID")
          },
          employee_name: {
            alias: t("common.Person Name")
          },
          timesheet_names: {
            alias: t("common.Timesheet")
          },
          att_dates: {
            alias: t("common.Date")
          },
          total_late_in: {
            alias: t("common.total_late_in")
          },
          total_early_out: {
            alias: t("common.total_early_out")
          },
          total_break: {
            alias: t("common.Total_break")
          },
          total_paid_work: {
            alias: t("common.Total_paid_work")
          }
        },
        title: t("common.Attendance Report"),
        file_type: exportFormat,
        share: []
      }
      if (!start_date || !end_date || !isValidDate(start_date) || !isValidDate(end_date)) {
        SnackbarUtil.error("The start date and end date are required, Please select the correct start and end dates",
          {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      } else {
        // setLoading(true)
        try {
          const data = export_meta;
          let params = ''
          if (Util.datetime2string(start_date, "yyyy-MM-dd") != Util.datetime2string(firstDay, "yyyy-MM-dd")
            || Util.datetime2string(end_date, "yyyy-MM-dd") != Util.datetime2string(new Date(), "yyyy-MM-dd")
            || searchKeyword) {
            setFilterParams({
              "all": true,
              date_range: [
                Util.datetime2string(start_date, "yyyy-MM-dd"),
                Util.datetime2string(end_date, "yyyy-MM-dd")
              ]
            })
            params = `date_range=${moment(start_date).format(
              'yyyy-MM-DD')}&date_range=${moment(end_date).format(
              'yyyy-MM-DD')}&keyword=${searchKeyword}&all=${true}`;
          } else if ( page != 0 || pageSize != 10) {
            setFilterParams({
              "all": false,
              date_range: [
                Util.datetime2string(start_date, "yyyy-MM-dd"),
                Util.datetime2string(end_date, "yyyy-MM-dd")
              ]
            })
            params = `current=${page + 1}&pageSize=${pageSize}&date_range=${moment(start_date).format(
              'yyyy-MM-DD')}&date_range=${moment(end_date).format(
              'yyyy-MM-DD')}&keyword=${searchKeyword}&all=${false}`;
          } else {
            setFilterParams({
              "all": false,
              date_range: [
                Util.datetime2string(start_date, "yyyy-MM-dd"),
                Util.datetime2string(end_date, "yyyy-MM-dd")
              ]
            })
            params = `current=${page + 1}&pageSize=${pageSize}&date_range=${moment(start_date).format(
              'yyyy-MM-DD')}&date_range=${moment(end_date).format(
              'yyyy-MM-DD')}&keyword=${searchKeyword}&all=${false}`;
          }
          const res = TimecardService.download_data(params, data, "attendance").then((response) => {
            const blob = new Blob([response.data], {type: response.headers["content-type"], endings: "transparent"})
            const url = URL.createObjectURL(blob)
            const aLink = document.createElement("a")
            aLink.style.display = "none"
            aLink.href = url
            document.body.appendChild(aLink)
            aLink.click()
            URL.revokeObjectURL(url)
            document.body.removeChild(aLink)
            SnackbarUtil.success(t("common.success"));
          });
        } catch (e) {
          // console.log(e)
        } finally {
          // setLoading(false)
          // setUpload(false)
        }
      }
      setExportFormat('')
    }
  }, [exportFormat])

  const defaultColumns: GridColDef[] = [
    {headerName: t("common.Person ID"), field: 'employee_code', width: 120, filterable: false},
    {headerName: t("common.Person Name"), field: 'employee_name', width: 160, filterable: false},
    // {headerName: t("common.Department"), field: 'department_name', width: 120, filterable: false},
    {headerName: t("common.Timesheet"), field: 'timesheet_names', width: 120, filterable: false},
    {headerName: t("common.Date"), field: 'att_dates', width: 120, type: "date", filterOperators: attDateOperators,},
    {headerName: t("common.total_late_in"), field: 'total_late_in', width: 140, filterable: false},
    {headerName: t("common.total_early_out"), field: 'total_early_out', width: 150, filterable: false},
    {headerName: t("common.Total_break"), field: 'total_break', width: 140, filterable: false},
    {headerName: t("common.Total_paid_work"), field: 'total_paid_work', width: 140, filterable: false},
  ]

  const navigate = useNavigate();
  const dataGridRef = useRef<DataGridRef>();
  const ExtraAction = (props: any) => {
    const dispatch = useContext(DataGridDispatchContext)
    const {page} = useContext(DataGridContext)
    const {pageSize} = useContext(DataGridContext)
    const {searchKeyword} = useContext(DataGridContext)
    setPage(page)
    setPageSize(pageSize)
    setSearchKeyword(searchKeyword)
    // setSearchKeywordText({"keyword":searchKeyword, "current": page+1, "pageSize": pageSize})

    const handleRefresh = () => {
      dispatch({type: "refreshed"})
    }

    return (
      <Fragment>
        <Grid item={true}>
          <ExportAction setExportFormat={(fileType: string) => {
            // props.setFilterParams({...props?.filterParams, keyword: searchKeyword});
            props.setExportFormat(fileType);
          }}/>
        </Grid>
        <Grid item={true}>
          <Box>
            <Grid
              container={true}
              // columnSpacing={1}
              sx={{
                alignItems: "center",
                marginLeft: "5px"
              }}
            >
              <Box sx={{ width: "150px" }}>
                <DatePicker
                  disableFuture
                  views={['year', 'month', 'day']}
                  value={start_date}
                  inputFormat={"dd-MM-yyyy"}
                  onChange={(newValue: any) => {
                    setStartDate(newValue);
                    setFilterParams({
                      date_range: [
                        Util.datetime2string(newValue, "yyyy-MM-dd"),
                        Util.datetime2string(end_date, "yyyy-MM-dd")
                      ]
                    })
                    if (props.setFilterParams) {
                      props.setFilterParams({...props?.filterParams, ...{date_range: Util.datetime2string(newValue, "yyyy-MM-dd")}})
                    }
                    handleRefresh()
                  }}
                  renderInput={(params) =>
                    <TextField
                      // fullWidth
                      size={"small"}
                      variant="outlined"
                      {...params}
                    />}
                />
              </Box>
              -
              <Box sx={{ width: "150px" }}>
                <DatePicker
                  disableFuture
                  views={['year', 'month', 'day']}
                  value={end_date}
                  minDate={start_date}
                  inputFormat={"dd-MM-yyyy"}
                  onChange={(newValue: any) => {
                    setEndDate(newValue)
                    setFilterParams({
                      date_range: [
                        Util.datetime2string(start_date, "yyyy-MM-dd"),
                        Util.datetime2string(newValue, "yyyy-MM-dd")
                      ]
                    })
                    if (props.setFilterParams) {
                      props.setFilterParams({...props?.filterParams, ...{date_range: Util.datetime2string(newValue, "yyyy-MM-dd")}})
                    }
                    handleRefresh()
                  }}
                  renderInput={(params) =>
                    <TextField
                      // fullWidth
                      size={"small"}
                      variant="outlined"
                      {...params}
                    />}
                />
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Fragment>
    )
  }

  const toolbar = {
    title: t("common.First In Last Out"),
    breadcrumbs: [
      {breadcrumbName: t("common.Report"), path: WebURL.ATT_REPORT_HOME},
      {breadcrumbName: t("common.Attendance Report"), path: WebURL.ATT_REPORT_MONTHLY_REPORT},
    ],
    searchTips: t("common.Search by Person ID, Person Name, Timesheet Name"),
    search: true,
    export: true,
    exportCallback: () => navigate(WebURL.ATT_REPORT_ATTENDANCE_REPORT_EXPORT),
    children: <ExtraAction filterParams={exportFilterParams} setFilterParams={setFilter} setExportFormat={setExportFormat} />
  }
  return (
    <>
      <Box
        sx={{
          '& .MuiDataGrid-row-height-no-limit': {
            maxHeight: '180px!important'
          },
        }}
      >
        <CustomDataGrid
          columns={defaultColumns}
          ref={dataGridRef}
          uri={ATTEndpoints.ATT_REPORT_ATTENDANCE_REPORT}
          requestParams={filterParams}
          getRowId={(row) => {
            return Math.random()
          }}
          toolbar={toolbar}
          localeText={{
            filterPanelColumns: t("common.Columns"),
            filterPanelOperators: t("common.Operators"),
          }}
        />
      </Box>
    </>
  )
}
export default AttendanceReport
