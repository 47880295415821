import React, {Fragment, useState} from "react";
import SnackbarUtil from '@/utils/SnackbarUtil';
import {useTranslation} from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip
} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import DeviceService from '@/services/dms/DeviceService';
import LoadingButton from '@mui/lab/LoadingButton';
import {ReactComponent as IconDaylightInfo} from '@/assets/icons/Daylight.svg';
import Util from "@/utils/Util";

function isValidDate(date: any) {
  return date instanceof Date && !isNaN(date.getTime()) && date.getFullYear() > 2000
}

const punchRecordStyle = {
  '& .MuiPaper-root': {width: '400px', top: '-10vh'},
  '.MuiDialog-paper': {maxWidth: '60vw'},
};

export function AddAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("common.Daylight Saving Time")}>
      <IconButton sx={{padding: "0px"}} onClick={() => {
        setInfo(true)
      }}>
        <IconDaylightInfo/>
      </IconButton>
    </Tooltip>
  )
}

export interface DeviceIdsProps {
  deviceIds: any
}

const DaylightSavingTime = (props: DeviceIdsProps) => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <AddAction setInfo={setInfo}/>
      </Grid>
      <DaylightSavingTimeContent info={info} setInfo={setInfo} deviceIds={props.deviceIds}/>
    </Fragment>
  )
}

const DaylightSavingTimeContent = ({info, setInfo, deviceIds}: any) => {
  const {t} = useTranslation()
  const [start_date, setStartDate] = useState<Date>(new Date());
  const [end_date, setEndDate] = useState<Date>(new Date());
  const [loading, setLoading] = React.useState(false);
  const saveDaylightTime = () => {
    const data = {
      start_date: Util.datetime2string(start_date),
      end_date: Util.datetime2string(end_date),
    }
    if (start_date > end_date) {
      SnackbarUtil.error(t("common.Invalid Date time Range"),
        {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      return;
    }
    if (!start_date || !end_date || !isValidDate(start_date) || !isValidDate(end_date)) {
      SnackbarUtil.error("The start date and end date are required, Please select the correct start and end dates",
        {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      return;
    }
    if (deviceIds.length == 0) {
      SnackbarUtil.error(t("common.Select device first"), { anchorOrigin: { vertical: 'top', horizontal: 'center' } })
      return;
    }
    if (deviceIds) {
      deviceIds.map((dev: any) => {
        DeviceService.DaylightSavingTime(dev, data).then(
          (result) => {
            SnackbarUtil.success('Success.')
          }
        )
      })
    }
    setInfo(false)
  }
  return (
    <Dialog
      open={info}
      onClose={() => {
        setInfo(false);
        setStartDate(new Date());
        setEndDate(new Date())
      }}
      sx={punchRecordStyle}
    >
      <DialogTitle>
        {t("common.Daylight Saving Time")}
      </DialogTitle>
      <DialogContent sx={{lineHeight: '25px', color: 'gray'}}>
        <Grid item sx={{marginTop: "10px"}}>
          <Grid sx={{marginTop: "20px"}}>
            <DateTimePicker
              label={t("common.Start Date")}
              value={start_date}
              maxDate={end_date}
              // views={["month", "day"]}
              inputFormat={"yyyy-MM-dd HH:mm"}
              onChange={(newValue: any) => {
                setStartDate(newValue);
              }}
              renderInput={(params) =>
                <TextField
                  fullWidth
                  // variant="standard"
                  {...params}
                />}
            />
          </Grid>
          <Grid sx={{marginTop: "20px"}}>
            <DateTimePicker
              label={t("common.End Date")}
              value={end_date}
              minDate={start_date}
              // views={["month", "day"]}
              inputFormat={"yyyy-MM-dd HH:mm"}
              onChange={(event: any) => {
                setEndDate(event)
              }}
              renderInput={(params) =>
                <TextField
                  fullWidth
                  // variant="standard"
                  {...params}
                />}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <LoadingButton
            id='ConfirmButton'
            loading={loading}
            onClick={() => (saveDaylightTime())}
            variant={"contained"}>{t('common.confirm')}</LoadingButton>
          <Button id='CancelButton' onClick={() => {
            setInfo(false);
            setStartDate(new Date());
            setEndDate(new Date())
          }}>{t('common.cancel')}</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default DaylightSavingTime;
