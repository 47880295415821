import {Box, Grid, Link, Stack, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {useTranslation} from "react-i18next";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import StepLabel from '@mui/material/StepLabel';
import SitePhoto from "@/assets/images/Site.svg";
import ZonePhoto from "@/assets/images/Zone.svg";
import {HREndpoints} from "@/services/hr/endpoints";
import WebURL from "@/urls";
import CustomDataGrid, {DataGridRef} from "@/components/data-grid/CustomDataGrid";
import {GridColDef, GridRenderCellParams, GridSelectionModel} from "@mui/x-data-grid";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {DMSEndpoints} from "@/services/dms/endpoints";
import {ATTEndpoints} from "@/services/att/endpoints";
import {object, promise, string, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {ngteco_timezones} from "@/components/resource/timezone";
import GenericService from "@/services/GenericService";
import {LocationEndpoints} from "@/services/location/endpoints";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import DeviceRegister from "@/pages/quick/components/device/DeviceRegister";
import Sync2Device from "@/pages/quick/components/device/Sync2Device";
import EmpRemove from "@/pages/quick/components/device/RemoveDeviceEmp";
import DepartmentAdd from "@/pages/quick/components/department/DepartmentAdd";
import DepartmentEdit from "@/pages/quick/components/department/DepartmentEdit";
import DepartmentDelete from "@/pages/quick/components/department/DepartmentDelete";
import EmployeeAdd from "@/pages/quick/components/employee/EmployeeAdd";
import EmployeeEdit from "@/pages/quick/components/employee/EmployeeEdit";
import EmpDelete from "@/pages/quick/components/employee/EmployeeDelete";
import TimeSheetAdd from "@/pages/quick/components/timesheet/TimeSheetAdd";
import ScheduleAdd from "@/pages/quick/components/schedule/ScheduleAdd";
import {getOrganSites} from "@/store/slices/Site";
import {DeviceListButtonDrawer} from "@/pages/att/Schedule/components/drawers/DeviceListDrawer";
import ScheduleDelete from "@/pages/quick/components/schedule/ScheduleDelete";
import TimesheetDelete from "@/pages/quick/components/timesheet/TimesheetDelete";
import TimesheetEdit from "@/pages/quick/components/timesheet/TimesheetEdit";
import ScheduleEdit from "@/pages/quick/components/schedule/ScheduleEdit";
import {getOrganDevice} from "@/store/slices/Device";


const QuickStart: React.FC = () => {
  const {t} = useTranslation();
  // bd12a5cb47ff48c89886327edfc082ca   8098231240010
  const steps = [t('common.Site'), t('common.Zone'), t('common.Device Management'),
    t('common.Add Employee'), t('common.Sync Employee'),
    t('common.Timesheet'), t('common.Shift Schedule')];
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const dataGridRef = useRef<DataGridRef>();
  const [deviceId, setDeviceId] = React.useState<string>('');
  const [getDeviceId, setGetDeviceId] = React.useState<string>('');
  const [saving, setSaving] = React.useState<boolean>(false)
  const [gridData, setGridData] = useState([]);
  const [selectionDeptModel, setSelectionDeptModel] = useState<GridSelectionModel>([]);
  const [selectionEmpModel, setSelectionEmpModel] = useState<GridSelectionModel>([]);
  const [selectionDevEmpModel, setSelectionDevEmpModel] = useState<GridSelectionModel>([]);
  const [selectionRemoveEmpModel, setSelectionRemoveEmpModel] = useState<GridSelectionModel>([]);
  const [selectionScheduleModel, setSelectionScheduleModel] = useState<GridSelectionModel>([]);
  const [selectionTimesheetModel, setSelectionTimesheetModel] = useState<GridSelectionModel>([]);
  const timezoneList = ngteco_timezones()
  const siteSchema = object({
    site_name: z.string().min(1).max(30, t("common.The length of field cannot exceed digits", {number: 30})),
    time_zone: z.object({
      value: z.string(),
      name: string()
    }, {required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")})
  })
  type siteInput = TypeOf<typeof siteSchema>;
  const siteForm = useForm<siteInput>({
    resolver: zodResolver(siteSchema),
    defaultValues: {
      site_name: '',
    }
  });
  const zoneSchema = object({
    zone_name: z.string({required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
      .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
    zone_site: z.object({
      id: z.string(),
      name: z.string(),
    }, {required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")}),
  })
  type zoneInput = TypeOf<typeof zoneSchema>;

  const sites: { id: string, name: string, address: Record<string, any> }[] = useSelector((state: any) => {
    return state.site.sites
  });
  const zoneForm = useForm<zoneInput>({
    resolver: zodResolver(zoneSchema),
  });
  const dispatch = useDispatch();

  const department_columns: GridColDef[] = [
    {field: 'name', headerName: t("common.Department Name"), minWidth: 150},
    {field: 'code', headerName: t("common.Department Code"), minWidth: 150},
    {
      field: 'actions', headerName: t("common.action"), headerAlign: 'center', align: 'center', minWidth: 100,
      renderCell: (e) => {
        return (
          <Stack direction={"row"}>
            <Grid sx={{marginRight: "20px"}}>
              <DepartmentEdit deptId={e.id.toString()}/>
            </Grid>
          </Stack>
        )
      }
    }
  ]
  const emp_columns: GridColDef[] = [
    {
      field: 'firstName', headerName: t("common.Person Name"), minWidth: 150, renderCell: (params) => {
        return (
          <Grid style={{display: "flex", alignItems: "center"}}>
            <Box style={{padding: '0 8px'}}>{params.row.firstName} {params.row.lastName}</Box>
          </Grid>
        )
      }
    },
    {field: 'employeeCode', headerName: t("common.Person ID"), type: "string", minWidth: 150},
    {field: 'department_name', headerName: t("common.Department"), type: "string", minWidth: 150},
    {
      field: 'actions', headerName: t("common.action"), headerAlign: 'center', align: 'center', minWidth: 100,
      renderCell: (e) => {
        return (
          <Stack direction={"row"}>
            <Grid sx={{marginRight: "20px"}}>
              <EmployeeEdit empId={e.id.toString()}/>
            </Grid>
          </Stack>
        )
      }
    }
  ]
  const person_in_device_columns: GridColDef[] = [
    {field: 'personCode', headerName: t("hr.employee.Person ID"), type: "string", minWidth: 180, flex: 0.2},
    {
      field: 'full_name',
      headerName: t("hr.employee.Person Name"),
      minWidth: 300,
      flex: 0.3,
      renderCell: (e: GridRenderCellParams) => (
        <Grid style={{display: "flex", alignItems: "center"}}>
          <Box style={{padding: '0 8px'}}>{e.row.firstName} {e.row.lastName}</Box>
        </Grid>
      )
    }
  ]
  const timesheet_columns: GridColDef[] = [
    {field: 'name', headerName: t("common.Timesheet Name"), minWidth: 200},
    {field: 'period', headerName: t("common.Unit"), minWidth: 180},
    {field: 'cycle', headerName: t("common.Cycle"), minWidth: 180},
    {field: 'description', headerName: t("common.Description"), type: "string", minWidth: 100},
    {
      field: 'actions', headerName: t("common.action"), headerAlign: 'center', align: 'center', minWidth: 100,
      renderCell: (e) => {
        return (
          <Stack direction={"row"}>
            <Grid sx={{marginRight: "20px"}}>
              <TimesheetEdit timesheetId={e.id.toString()}/>
            </Grid>
          </Stack>
        )
      }
    }
  ]
  const schedule_columns: GridColDef[] = [
    {field: 'employee_name', headerName: t("common.Name"), minWidth: 150,},
    {field: 'employee_code', headerName: t("hr.employee.Person ID"), minWidth: 100,},
    {field: 'department_name', headerName: t("hr.department.Department"), minWidth: 100,},
    {field: 'timesheet_name', headerName: t("att.schedule.Timesheet"), minWidth: 100,},
    {field: 'assigned_start', headerName: t("att.schedule.Start Date"), type: "date", minWidth: 80,},
    {field: 'assigned_end', headerName: t("att.schedule.End Date"), type: "date", minWidth: 80,},
    {
      field: 'actions', headerName: t("common.action"), headerAlign: 'center', align: 'center', minWidth: 50,
      renderCell: (e) => {
        return (
          <Stack direction={"row"}>
            <Grid sx={{marginRight: "20px"}}>
              <ScheduleEdit scheduleId={e.id.toString()}/>
            </Grid>
          </Stack>
        )
      }
    }
  ]
  const scheduleValidate = object({
    device_list:
      z.array(
        z.record(
          z.string(), z.string().nullable()
        ), {}
      ),
  })
  type scheduleInput = TypeOf<typeof scheduleValidate>
  const scheduleForm = useForm<scheduleInput>({
    resolver: zodResolver(scheduleValidate),
  })
  const deviceValidate = object({
    sn: z.string({required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
      .max(30, t("common.The length of field cannot exceed digits", {number: 30}))
  })
  type deviceInput = TypeOf<typeof deviceValidate>;
  const select_device: { id: string, sn: string }[] = useSelector((state: any) => {
    return state.device.devices
  });
  const deviceForm = useForm<deviceInput>({
    resolver: zodResolver(deviceValidate),
  });

  const isStepOptional = (step: number) => {
    if (step === 6 || step === 0) {
      return false
    } else {
      return true
    }
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleSkip = () => {
    let newSkipped = skipped;
    if (activeStep === 0) {
      dispatch<any>(getOrganSites());
    }
    if (activeStep === 3) {
      dispatch<any>(getOrganDevice());
    }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (activeStep === 2) {
      if (!getDeviceId) {
        SnackbarUtil.error('Device SN has no value or Device do not exist.', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
        return false
      }
    }
    if (activeStep === 3) {
      dispatch<any>(getOrganDevice());
    }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleNextOne = () => {
    let newSkipped = skipped;
    const values = siteForm.getValues()
    if (!values.site_name || (values.time_zone == undefined)) {
      SnackbarUtil.error('site name or time zone has no value.', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return false
    }
    if (values.site_name.length > 30) {
      SnackbarUtil.error(t("common.The length of field cannot exceed digits", {number: 30}),
        {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      return;
    }
    try {
      const newSite = {
        name: values.site_name,
        address: {
          timeZone: values.time_zone.value,
          city: "default",
          addressLine1: "default",
        }
      }
      GenericService.create(LocationEndpoints.SITE_URI, newSite).then(response => {
        if (response.status >= 400) {
          SnackbarUtil.error(response.message, {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
        } else {
          SnackbarUtil.success(t('common.Site added successfully.'), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
          if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setSkipped(newSkipped);
        }
      })
    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
      dispatch<any>(getOrganSites());
    }
    dispatch<any>(getOrganSites());
  };
  const handleNextTwo = () => {
    let newSkipped = skipped;
    const values = zoneForm.getValues()
    if (!values.zone_name || (values.zone_site == undefined)) {
      SnackbarUtil.error('zone name or site has no value.', {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      return false
    }
    if (values.zone_name.length > 30) {
      SnackbarUtil.error(t("common.The length of field cannot exceed digits", {number: 30}),
        {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      return;
    }
    try {
      const newZone = {
        name: values.zone_name,
        siteId: values.zone_site.id,
      }
      GenericService.create(LocationEndpoints.ZONE_URI, newZone).then(response => {
        if (response.status >= 400) {
          SnackbarUtil.error(response.message, {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
        } else {
          SnackbarUtil.success(t('common.Zone added successfully.'), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
          if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
          }

          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setSkipped(newSkipped);
        }
      })
    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
    }
  };

  const handleBack = () => {
    if (activeStep == 3) {
      setGetDeviceId('')
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleDataGridSave = (value: any) => {
    if (value) {
      setGridData(value)
    }
  }

  return (
    <Box sx={{width: '100%'}}>
      <Grid className={"toolbar-container"}>
        <CustomBreadcrumbs record={[{label: t("common.Quick Start")}]}/>
      </Grid>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          // if (isStepOptional(index)) {
          //   labelProps.optional = (
          //     <Typography variant="caption">Optional</Typography>
          //   );
          // }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{mt: 2, mb: 1}}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
            <Box sx={{flex: '1 1 auto'}}/>
            <Button onClick={handleReset}>Return to the first page</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{mt: 2, mb: 1}}>Step {activeStep + 1}</Typography>
          {activeStep === 0 && <Grid item sx={{display: "inline-flex"}} xs={12}>
            <Grid xs={6}>
              <Typography sx={{width: "40vw", height: "50px", color: "#0b2200"}}>
                <h3>Create A New Site</h3>
              </Typography>
              <Typography sx={{width: "400px"}}>
                Guide:
              </Typography>
              <Typography sx={{whiteSpace: "pre-wrap", width: "500px", marginTop: "5px"}}>
                1.You must first create a site,which will be used to bind your device.(For first-time users of this
                software)
              </Typography>
              <Typography sx={{whiteSpace: "pre-wrap", width: "500px", marginTop: "5px"}}>
                2.Site generally refers to a rough geographical location,which you can named based on.
              </Typography>
              <Typography sx={{whiteSpace: "pre-wrap", width: "500px", marginTop: "5px"}}>
                3.After you create a site,you will be asked to continue creating zones belonging to this site,as well
                as devices bound to this site.
                <h3>Site information:</h3>
                <Grid sx={{maxWidth: "300px"}}>
                  <ControlledTextFiled fullWidth label={t("common.Site Name")} name={"site_name"}
                                       hookForm={siteForm}/>
                  <ControlAutoComplete label={t("common.Site Time Zone")} name={'time_zone'} form={siteForm}
                                       options={timezoneList}
                                       isOptionEqualToValue={(option, value) => option.value === value.value}
                  />
                </Grid>
                <Link
                  underline={"none"}
                  onClick={handleSkip}
                  sx={{
                    cursor: "pointer",
                    padding: "0 8px",
                  }}
                >
                  <Typography>{t("common.Already have a site? Skip this step")}</Typography>
                </Link>
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Box sx={{display: "flex", justifyContent: "right"}}>
                <img src={SitePhoto} style={{width: "672px", height: "387px"}}></img>
              </Box>
            </Grid>
          </Grid>}
          {activeStep === 1 && <Grid item sx={{display: "inline-flex"}} xs={12}>
            <Grid xs={6}>
              <Typography sx={{width: "40vw", height: "50px", color: "#0b2200", position: 'relative'}}>
                <h3>Create A New Zone</h3>
              </Typography>
              <Typography sx={{width: "40vw"}}>
                Guide:
              </Typography>
              <Typography sx={{whiteSpace: "pre-wrap"}}>
                1.When creating this zone,please note that you must select a site,which will contains this zone.
              </Typography>
              <Typography>
                <h3>Zone information:</h3>
                <Grid sx={{maxWidth: "300px"}}>
                  <ControlledTextFiled fullWidth label={t("common.Zone Name")} name={"zone_name"} hookForm={zoneForm}/>
                  <ControlAutoComplete label={t("common.Zone belongs to a Site")} name={"zone_site"} form={zoneForm}
                                       options={sites}
                  />
                </Grid>
              </Typography>
              <Link
                underline={"none"}
                onClick={handleSkip}
                sx={{
                  cursor: "pointer",
                  padding: "0 8px",
                }}
              >
                <Typography>{t("common.Already have a zone? Skip this step")}</Typography>
              </Link>
            </Grid>
            <Grid xs={6}>
              <Box sx={{display: "flex", justifyContent: "right"}}>
                <img src={ZonePhoto} style={{width: "556px", height: "359px"}}></img>
              </Box>
            </Grid>
          </Grid>}
          {activeStep === 2 && <Grid item sx={{display: "inline-flex"}} xs={12}>
            <Grid xs={12}>
              <Typography sx={{width: "800px", height: "50px", color: "#0b2200", position: 'relative'}}>
                <h3>Add A New Device</h3>
              </Typography>
              <Typography>
                <DeviceRegister deviceId={setGetDeviceId}/>
              </Typography>
              <Link
                underline={"none"}
                onClick={handleSkip}
                sx={{
                  cursor: "pointer",
                  padding: "0 8px",
                }}
              >
                <Typography>{t("common.No need to add a new device? Skip this step")}</Typography>
              </Link>
            </Grid>
          </Grid>}
          {activeStep === 3 && <Grid>
            <Typography sx={{width: "100%", height: "80px", color: "#0b2200"}}>
              <h3>Add new employees and department</h3>
              First you need to create new department and add your employees...
            </Typography>
            <Link
              underline={"none"}
              onClick={handleSkip}
              sx={{
                cursor: "pointer",
                padding: "0 8px",
              }}
            >
              <Typography>{t("common.No need to add employee? Skip this step")}</Typography>
            </Link>
            <Grid item sx={{display: "inline-flex"}} xs={12}>
              <Grid xs={6} sx={{minWidth: "30vw"}}>
                <CustomDataGrid
                  ref={dataGridRef}
                  uri={HREndpoints.HR_DEPARTMENT_URI}
                  columns={department_columns}
                  checkboxSelection={true}
                  onDataChange={handleDataGridSave}
                  onSelectionModelChange={(ids) => {
                    setSelectionDeptModel(ids);
                  }}
                  selectionModel={selectionDeptModel}
                  toolbar={{
                    title: t("common.Department"),
                    search: true,
                    searchTips: t("common.Search by Department Name, Department Code"),
                    breadcrumbs: [
                      {breadcrumbName: t("common.Department"), path: WebURL.HR_DEPARTMENT}
                    ],
                    extraComponentOnLeft: <DepartmentAdd/>,
                    extraComponentOnRight: <DepartmentDelete deptIds={selectionDeptModel}/>
                  }}
                />
              </Grid>
              <Grid xs={6} sx={{minWidth: "50vw", marginLeft: "10px"}}>
                <CustomDataGrid
                  uri={HREndpoints.HR_EMPLOYEE_URI}
                  columns={emp_columns}
                  ref={dataGridRef}
                  checkboxSelection={true}
                  onDataChange={handleDataGridSave}
                  onSelectionModelChange={(ids) => {
                    setSelectionEmpModel(ids);
                  }}
                  selectionModel={selectionEmpModel}
                  toolbar={{
                    title: t("common.Person"),
                    search: true,
                    searchTips: t("common.Search by Person First Name"),
                    breadcrumbs: [
                      {breadcrumbName: t("common.Person"), path: WebURL.HR_EMPLOYEE}
                    ],
                    extraComponentOnLeft: <EmployeeAdd/>,
                    extraComponentOnRight: <EmpDelete empIds={selectionEmpModel}/>
                  }}
                />
              </Grid>
            </Grid>
          </Grid>}
          {activeStep === 4 && <Grid>
            <Typography sx={{width: "350px", height: "50px", color: "#0b2200"}}>
              <h3>Add Employees to Your Device</h3>
            </Typography>
            <Typography>
              Now you already added your device.Then you need to add some employees to your device.You can only
              schedule these employees after adding them.
            </Typography>
            <Typography>
              Note: If you do not want an employee to punch in/out on this device, please do not add that employee to
              this device.
            </Typography>
            <Link
              underline={"none"}
              onClick={handleSkip}
              sx={{
                cursor: "pointer",
                padding: "0 5px",
              }}
            >
              <Typography>{t("common.No need to add employee to your device? Skip this step")}</Typography>
            </Link>
            <Typography sx={{width: "350px", height: "30px", color: "#0b2200"}}>
              <h3>Please Select Device first</h3>
            </Typography>
            <Grid sx={{maxWidth: "250px", maxHeight: "50px", ".MuiOutlinedInput-root": {padding: "1px"}}}>
              <ControlAutoComplete name={"sn"} form={deviceForm} label={''}
                                   options={select_device}
                                   multi={false}
                                   onChange={(event) => {
                                     scheduleForm.setValue("device_list", event)
                                     setDeviceId(event?.id)
                                   }}
              />
            </Grid>
            {deviceId && <Grid item sx={{display: "inline-flex"}} xs={12}>
              <Grid xs={6} sx={{minWidth: "35vw", marginLeft: "10px", marginTop: "10px"}}>
                <CustomDataGrid
                  uri={HREndpoints.HR_EMPLOYEE_URI}
                  columns={emp_columns}
                  ref={dataGridRef}
                  checkboxSelection={true}
                  onDataChange={handleDataGridSave}
                  onSelectionModelChange={(ids) => {
                    setSelectionDevEmpModel(ids);
                  }}
                  selectionModel={selectionDevEmpModel}
                  toolbar={{
                    title: '',
                    search: true,
                    searchTips: t("common.Search by Person First Name"),
                    breadcrumbs: [
                      {breadcrumbName: t("common.Choose Person"), path: WebURL.HR_EMPLOYEE}
                    ],
                    extraComponentOnRight: <Sync2Device deviceId={deviceId} empIds={selectionDevEmpModel}/>
                  }}
                />
              </Grid>
              <Grid xs={6} sx={{minWidth: "45vw", marginTop: "10px", marginLeft: "10px",}}>
                <CustomDataGrid
                  uri={`${DMSEndpoints.DMS_DEVICE_PERSON_URI}${deviceId?.toString() || ""}/person_list/`}
                  getRowId={(row) => row.personId}
                  checkboxSelection={true}
                  ref={dataGridRef}
                  columns={person_in_device_columns}
                  onDataChange={handleDataGridSave}
                  onSelectionModelChange={(ids) => {
                    setSelectionRemoveEmpModel(ids);
                  }}
                  selectionModel={selectionRemoveEmpModel}
                  columnVisibilityModel={{
                    deviceId: false
                  }}
                  experimentalFeatures={{newEditingApi: true}}
                  toolbar={{
                    title: "",
                    searchTips: t("common.Search by Person ID"),
                    search: true,
                    breadcrumbs: [
                      {breadcrumbName: t("common.Person in this device"), path: ""}
                    ],
                    extraComponentOnRight: <EmpRemove deviceId={deviceId} empIds={selectionRemoveEmpModel}/>,
                    filter: false
                  }}
                />
              </Grid>
            </Grid>}
          </Grid>}
          {activeStep === 5 && <Grid>
            <Typography sx={{width: "100%", height: "50px", color: "#0b2200"}}>
              <h3>Create A New Timesheet</h3>
            </Typography>
            <Typography>
              Guide:
            </Typography>
            <Typography>
              1.Please create a timesheet that meets your needs.
            </Typography>
            <Typography>
              2.After creating the Timesheet,click Next and you can bind it to certain employees.
            </Typography>
            <Link
              underline={"none"}
              onClick={handleSkip}
              sx={{
                cursor: "pointer",
                padding: "0 8px",
              }}
            >
              <Typography>{t("common.No need to create a new timesheet? Skip this step")}</Typography>
            </Link>
            <Grid item sx={{display: "inline-flex"}} xs={12}>
              <Grid xs={12} sx={{minWidth: "80vw", marginLeft: "10px", marginTop: "10px"}}>
                <CustomDataGrid
                  uri={ATTEndpoints.ATT_TIMESHEET_URI}
                  columns={timesheet_columns}
                  ref={dataGridRef}
                  checkboxSelection={true}
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionTimesheetModel(newSelectionModel);
                  }}
                  selectionModel={selectionTimesheetModel}
                  toolbar={{
                    title: '',
                    search: true,
                    searchTips: t("common.Search by Timesheet Name"),
                    breadcrumbs: [
                      {breadcrumbName: t("common.TimeSheet"), path: WebURL.ATT_TIMESHEET}
                    ],
                    extraComponentOnLeft: <TimeSheetAdd/>,
                    extraComponentOnRight: <TimesheetDelete timesheetIds={selectionTimesheetModel}/>
                  }}
                />
              </Grid>
            </Grid>
          </Grid>}
          {activeStep === 6 && <Grid>
            <Typography sx={{width: "100%", height: "50px", color: "#0b2200"}}>
              <h3>Schedule Timesheet for Your Employees</h3>
            </Typography>
            <Typography>
              Guide:
            </Typography>
            <Typography>
              · Last step - Please assign a Timesheet to your employees.
            </Typography>
            <Grid item sx={{display: "inline-flex"}} xs={12}>
              <Grid xs={12} sx={{minWidth: "80vw", marginLeft: "10px", marginTop: "10px"}}>
                <CustomDataGrid
                  uri={ATTEndpoints.ATT_SCHEDULE_URI}
                  columns={schedule_columns}
                  ref={dataGridRef}
                  checkboxSelection={true}
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionScheduleModel(newSelectionModel);
                  }}
                  selectionModel={selectionScheduleModel}
                  toolbar={{
                    title: '',
                    search: true,
                    searchTips: t("common.Search by Person ID, Person Name, Timesheet Name"),
                    breadcrumbs: [
                      {breadcrumbName: t("att.schedule.Shift schedule"), path: WebURL.ATT_TIMESHEET}
                    ],
                    extraComponentOnLeft: <ScheduleAdd/>,
                    extraComponentOnRight: <Grid sx={{display: "flex"}}><DeviceListButtonDrawer
                      IDs={selectionScheduleModel}/><ScheduleDelete scheduleIds={selectionScheduleModel}/></Grid>,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>}
          <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
            {activeStep !== 0 && <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{mr: 1, backgroundColor: '#0084d0'}}
            >
              Back
            </Button>}
            <Box sx={{flex: '1 1 auto'}}/>
            {activeStep === 0 && <Button color="inherit" onClick={handleNextOne} sx={{backgroundColor: '#0084d0'}}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>}
            {activeStep === 1 && <Button color="inherit" onClick={handleNextTwo} sx={{backgroundColor: '#0084d0'}}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>}
            {[2, 3, 4, 5, 6].includes(activeStep) &&
              <Button color="inherit" onClick={handleNext} sx={{backgroundColor: '#0084d0'}}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
export default QuickStart
