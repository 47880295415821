import React, { useRef } from "react";
import {GridColDef, GridColTypeDef} from "@mui/x-data-grid";
import {Box, Grid, Stack} from "@mui/material";
import {LocationEndpoints} from "@/services/location/endpoints";
import {Add} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import WebURL from '@/urls';
import {useNavigate} from "react-router-dom";
import CustomRowButton from '@/components/data-grid/CustomRowButton';
import CustomDataGrid, { DataGridRef } from '@/components/data-grid/CustomDataGrid';
import Dialog from "@/components/dialog";
import GenericService from "@/services/GenericService";
import SnackbarUtil from '@/utils/SnackbarUtil';
import { ngteco_timezones } from "@/components/resource/timezone";
import DeviceService from "@/services/dms/DeviceService";
import responseCodeAlert from "@/utils/codes";
import { useTranslation } from "react-i18next";

const SiteDataGrid: React.FC = () => {
  const {t} = useTranslation();
  const timezoneList = ngteco_timezones();
  const navigate = useNavigate();
  const dataGridRef = useRef<DataGridRef>();
  const timezoneCol: GridColTypeDef = {
    type: "string",
    flex: 0.4,
    valueGetter: value => value.row.address.timeZone,
    renderCell: (params) => {
      const timezone = params.row.address.timeZone
      if(!timezone){
        return '-'
      }
      return timezoneList.find(option => option.value === timezone)?.name || `UTC${timezone}`
    }
  }
  const columns: GridColDef[] = [
    {field: 'name', headerName: t("common.Site Name"), type: "string", minWidth: 120, flex: 0.3},
    {field: 'timezone', headerName: t("common.Site Time Zone"), ...timezoneCol},
    // {field: 'description', headerName: t("common.Description"), type: "string", minWidth: 200, flex: 0.2},
    {
      field: 'actions', headerName: t("common.Action"), headerAlign: "center", align: "center", flex: 0.4 ,
        renderCell: (cell) => {
          return (
            <Stack direction={"row"}>
              <CustomRowButton insideIcon={EditIcon} buttonProps={{
                key: "edit",
                onClick: () => {navigate(WebURL.LOCATION_SITE_EDIT,  {state: {id: cell.row.id}});}
              }}/>
              <CustomRowButton insideIcon={DeleteForeverIcon} buttonProps={{
                key: "delete",
                onClick: async () => {
                  Dialog.confirm({
                    title: t("location.site.Delete Site?"),
                    content: t("location.site.Delete Confirm"),
                    onConfirm: () => {
                      DeviceService.list({current: 1, pageSize: 1, siteId: cell.id}).then(({data}) => {
                        if (data.total === 0) {
                          GenericService.destroy(LocationEndpoints.SITE_URI, `${cell.id}`).then(
                            (result) => {
                              console.log("delete====", result)
                              SnackbarUtil.success(t('common.success'))
                              // navigate("/location/site")
                              dataGridRef.current?.refresh();
                          });
                        } else {
                          SnackbarUtil.error(t("location.site.alreadyBinding"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}});
                        }
                      })
                    }
                  });
                }
              }}/>
            </Stack>
          )
        }
    },
  ]
  return (
    <CustomDataGrid
      uri={LocationEndpoints.SITE_URI}
      columns={columns}
      checkboxSelection={false}
      ref={dataGridRef}
      toolbar={{
        title: t("common.Site View"),
        searchTips: t("location.site.Search by Site Name"),
        filter: false,
        breadcrumbs: [
          {breadcrumbName: t("common.Site"), path: WebURL.HR_EMPLOYEE}
        ],
        actions: [{
          key: 'add', icon: Add, helpTxt: t("common.add"), callback: async () => {
            navigate(WebURL.LOCATION_SITE_ADD);
          }
        }]
      }}

    />
  )
}
export default SiteDataGrid
