import {ngteco_timezones} from "@/components/resource/timezone";
import {getOrganSites} from "@/store/slices/Site";
import {getOrganZones} from "@/store/slices/Zone";
import {zodResolver} from "@hookform/resolvers/zod";
import {
  Drawer,
  Grid,
  Stack,
  useTheme,
  Avatar,
  Alert,
  Box,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress
} from "@mui/material";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import React, {useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {object, TypeOf, z} from "zod";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import DeviceService from "@/services/dms/DeviceService";
import WebURL from "@/urls";
import SiteService from "@/services/location/SiteService";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import ZoneService from "@/services/location/ZoneService";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";

const customDrawerStyle = {
  "& .MuiDrawer-paper": {width: '30vw'}
}

type DeviceBindingProps = {
  visible: boolean;
  onClose: () => void;
  device?: { deviceId: string, sn: string };
}

const DeviceBinding: React.FC<DeviceBindingProps> = (props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const deviceSchema = object({
    site: z.object({
      id: z.string(),
      name: z.string(),
      address: z.object({
        timeZone: z.string().nullish()
      })
    }, {required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")}),
    // zone: z.object({
    //   id: z.string(),
    //   name: z.string()
    // }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
    timezone: z.union([z.object({
      value: z.string(),
      code: z.string(),
      group: z.string(),
      name: z.string()
    }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")}),
      z.string({ required_error: t("common.This field is required!")})])
  });
  type deviceType = TypeOf<typeof deviceSchema>;


  const navigate = useNavigate();
  const deviceForm = useForm<deviceType>({
    resolver: zodResolver(deviceSchema)
  })
  const sites: { id: string, name: string, address: Record<string, any> }[] = useSelector((state: any) => state.site.sites);
  // const zones: {id: string, name: string}[] = useSelector((state: any) => state.zone.zones);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(getOrganSites());
    dispatch<any>(getOrganZones());
  }, [])
  const [loading, setLoading] = useState<boolean>(false);
  const timezoneList = ngteco_timezones();
  const [timeZone, setTimeZone] = useState<string>();
  const [timezoneEmpty, setTimezoneEmpty] = useState<boolean>(false);

  const [zoneLoading, setZoneLoading] = useState<boolean>(false);
  const [zones, setZones] = useState<[]>([]);
  const [zoneInputValue, setZoneInputValue] = useState<any>('');
  const [zoneValue, setZoneValue] = useState<any>(null);
  const [selectZone, setSelectZone] = useState<boolean>(true);
  return (
    <>
      <Drawer sx={customDrawerStyle} anchor="right" open={props.visible} onClose={props.onClose}>
        <Grid sx={{marginTop: '3vh', fontSize: '1.6rem', textAlign: 'center'}}>
          {t("dms.tip01")}
        </Grid>
        <Stack spacing={2} sx={{margin: '0 1vw'}}>
          <Grid sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1vw'}}>
            <Grid sx={{textAlign: 'center', color: theme.palette.primary.main}}>
              {/* <CheckCircle sx={{width: '3vw', height: '3vw'}} /> */}
              {/* <Grid> Added Successfully </Grid> */}
            </Grid>
          </Grid>
          <Grid>
            <Stack direction="row" spacing={2}>
              <Avatar sx={{width: 50, height: 50}}>
                <AdUnitsIcon sx={{fontSize: 30}} color="action"/>
              </Avatar>
              <Box sx={{fontSize: 16, display: "flex", alignItems: "center"}}>
                <Box sx={{color: "gray"}}>
                  {t("dms.Serial Number")}: {props.device?.sn}
                </Box>
              </Box>
            </Stack>
            <Box>
              <Typography gutterBottom>{t("dms.tip02")}<br/></Typography>
              <Typography gutterBottom color={theme.palette.primary.main}>{t("dms.tip03")}</Typography>
            </Box>
            <Box component={"form"}>
              <Stack sx={{lineHeight: '5vh'}}>
                <ControlAutoComplete
                  label={t("dms.Bind Site")}
                  name={"site"}
                  form={deviceForm}
                  required={true}
                  options={sites}
                  onChange={(value) => {
                    const timezone = value?.address.timeZone;
                    if (timezone) {
                      deviceForm.setValue('timezone', timezoneList.find(item => item.value === timezone)?.name || `UTC${timezone}`);
                      setTimezoneEmpty(false);
                      setTimeZone(undefined);
                    } else {
                      setTimezoneEmpty(true);
                    }
                    setZoneLoading(true);
                    setZones([]);
                    setZoneInputValue('');
                    setZoneValue(null);
                    ZoneService.list({keyword: value.id, current: 1, pageSize: 100}).then((zones) => {
                      const {data} = zones.data;
                      setZones(data);
                      setZoneLoading(false);
                    });
                  }}
                />
                {
                  timezoneEmpty
                    ? <ControlAutoComplete label={t("dms.Site Timezone")} name={'timezone'} form={deviceForm}
                                           options={timezoneList} required={true} groupBy={(option) => option.group}
                                           isOptionEqualToValue={(option, value) => option.value === value.value}
                                           onChange={({value}) => {
                                             setTimeZone(value);
                                             deviceForm.setValue('timezone', value);
                                           }}
                    />
                    : <ControlledTextFiled
                      label={t("dms.Site Timezone")}
                      fullWidth
                      name={"timezone"}
                      hookForm={deviceForm}
                      InputProps={{
                        readOnly: true,
                        // disabled: true,
                      }}
                    />
                }
                <Autocomplete
                  options={zones}
                  loading={loading}
                  value={zoneValue}
                  onChange={(_, v) => setZoneValue(v)}
                  inputValue={zoneInputValue}
                  onInputChange={(_, v) => setZoneInputValue(v)}
                  getOptionLabel={option => option.name}
                  renderInput={(params) =>
                    <>
                      <NGInputLabel label={t("dms.Bind Zone")} required={true}/>
                      <TextField
                        {...params}
                        // label={t("dms.Bind Zone")}
                        required={true}
                        error={!selectZone}
                        helperText={(!selectZone && t("common.This field is required!")) || " "}
                        InputProps={{
                          ...params.InputProps,
                          autoComplete: "false",
                          endAdornment: (
                            <React.Fragment>
                              {loading && <CircularProgress color="inherit" size={20}/>}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    </>}
                />
              </Stack>
            </Box>
          </Grid>
        </Stack>
        <Grid sx={{position: 'fixed', bottom: '5vh'}}>
          <Stack direction="row" spacing={1}>
            <Box>
              <CancelButton
                sx={{width: '13vw', marginLeft: '1.5vw', textTransform: "none"}}
                onClick={() => {
                  deviceForm.reset({});
                  setZones([]);
                  setSelectZone(true);
                  props.onClose();
                }}/>
            </Box>
            <Box>
              <SavingButton
                loading={loading}
                sx={{width: '13vw', textTransform: "none"}}
                onClick={async () => {
                  setLoading(true);
                  try {
                    const isValid = await deviceForm.trigger();
                    if (isValid && !!zoneValue) {
                      setSelectZone(true);
                      const values = deviceForm.getValues();
                      // set regtistartion device default
                      const origin = {
                        ...props.device,
                        siteId: values.site.id,
                        zoneId: zoneValue.id,
                        registrationDevice: 1
                      };
                      const site_res = timezoneEmpty ?
                        await SiteService.update(values.site.id, {
                          address: {
                            timeZone,
                            city: "default",
                            addressLine1: "default",
                            siteId: values.site.id
                          }
                        }) : {}
                      const device_res = await DeviceService.deviceBinding(origin);
                      props.onClose();
                      navigate(WebURL.DMS_DEVICE);
                    } else {
                      setSelectZone(false);
                    }
                  } catch (e) {
                    console.log("eeeeeeeeee", e);
                  } finally {
                    setLoading(false);
                  }
                }}/>
            </Box>
          </Stack>
        </Grid>
      </Drawer>
    </>
  )
}

export default DeviceBinding;
