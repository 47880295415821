import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {Dialog, DialogTitle, Grid, IconButton, Stack, Tooltip} from "@mui/material";
import {Add} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {object, TypeOf, z} from "zod";
import {useSnackbar} from "notistack";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import EmployeeService from "@/services/hr/EmployeeService";
import EmployeeForm from "@/pages/quick/components/employee/EmployeeForm";

export function AddAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("common.New Person")}>
      <IconButton sx={{borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)"}} onClick={() => {
        setInfo(true)
      }}>
        <Add/>
      </IconButton>
    </Tooltip>
  )
}


const EmployeeAdd = () => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <AddAction setInfo={setInfo}/>
      </Grid>
      <AddDeptDialog info={info} setInfo={setInfo}/>
    </Fragment>
  )
}

const AddDeptDialog = ({info, setInfo}: any) => {
  const {t} = useTranslation()
  const codeRegx = /^[a-zA-Z0-9]+$/
  const employeeValidate = object({
    code: z.string({required_error: t("common.This field is required!")}).regex(codeRegx, t("common.No special character allowed!")).min(1, t("common.This field is required!"))
      .max(14, t("common.The length of field cannot exceed digits", {number: 14})),
    firstName: z.string({required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
      .max(25, t("common.The length of field cannot exceed digits", {number: 25})),
    lastName: z.string({required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
      .max(25, t("common.The length of field cannot exceed digits", {number: 25})),
    departmentIdOrCode: z.string().optional(),
    dept: z.object({
      id: z.string(),
      name: z.string(),
    }, {required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")}),
  })
  type employeeInput = TypeOf<typeof employeeValidate>;
  const [saving, setSaving] = React.useState<boolean>(false)
  const employeeForm = useForm<employeeInput>({
    resolver: zodResolver(employeeValidate)
  });
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async (event: any) => {
    setSaving(true)
    try {
      const values = employeeForm.getValues()
      values.departmentIdOrCode = values.dept.id
      const result = await EmployeeService.add(values);
      if (result.status < 400) {
        enqueueSnackbar(t("common.Person added successfully."), {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
      }
    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
      setInfo(false)
    }
  }
  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      // sx={}
    >
      <DialogTitle>
        {t("common.New Person")}
        <IconButton
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <EmployeeForm employeeForm={employeeForm} />
      <Grid style={{'padding':'30px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end" >
          <CancelButton onClick={() => {
            setInfo(false)
          }}/>
          <SavingButton loading={saving} onClick={employeeForm.handleSubmit(onSubmit)}/>
        </Stack>
      </Grid>
    </Dialog>
  )
}

export default EmployeeAdd;
