import { GridColDef } from "@mui/x-data-grid";
import { Box, Grid, Breadcrumbs, Typography, Badge, IconButton, Tooltip } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { ATTEndpoints } from "../../../services/att/endpoints";
import React, { useEffect, useState } from "react";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import GenericService from "@/services/GenericService";
import { useTranslation } from "react-i18next";

type FilterType = {
  date_range: [any, any],
}

const customDataGridStyle = {
  '& .MuiDataGrid-columnHeaders': { backgroundColor: '#F0F1F4!important', color: '#304669!important' },
  '.MuiDataGrid-columnHeader:focus-within': { outline: 'none' },
  '.MuiDataGrid-cell': { borderBottom: '0px', color: '#304669' },
  '.MuiDataGrid-cell:focus-within': { outline: 'none!important', color: '#0084d0!important' },
  '.MuiDataGrid-columnSeparator': { display: 'none !important' },
  '.MuiSvgIcon-root': { backgroundColor: 'transparent' },
  '.MuiDataGrid-root': { padding: '8px', borderRadius: '4px' },
  '.MuiDataGrid-row': { borderBottom: '1px solid #E7EBF0', maxHeight: '48px!important', minHeight: '48px!important' },
  '.MuiCheckbox-root': { color: '#D1D1D1' },
  '.MuiDataGrid-cellContent': {
    color: '#304669!important',
    fontFamily: 'Roboto',
    letterSpacing: '0.4px',
    fontSize: '14px'
  },
  '.MuiDataGrid-virtualScrollerRenderZone': { backgroundColor: '#FFFF' },
  '.MuiDataGrid-footerContainer': { display: 'block', width: '100%', border: '0px' },
  '.MuiTablePagination-spacer': { display: 'none' },
  '.MuiTablePagination-actions': { position: 'absolute', right: '0px', backgroundColor: '#FFF' },
  '.MuiTablePagination-displayedRows': { position: 'absolute', right: '90px' },
  // '.MuiDataGrid-virtualScroller': {overflow: 'hidden'},
  '.MuiTablePagination-select': { backgroundColor: '#F0F1F4', borderRadius: '4px!important' },
  '.MuiTablePagination-toolbar': { paddingLeft: '0px' },
  '.MuiIconButton-root.Mui-disabled': { backgroundColor: '#F0F1F4', borderRadius: '0px' },
  // '.MuiIconButton-root': {borderRadius: '0px'},
  '.Mui-checked': { color: 'rgb(54, 201, 109)!important' },
};
type GridData = {
  data: any;
  total: number;
}


const EmployeeAttendance: React.FC = () => {
  const { t } = useTranslation();
  const columns: GridColDef[] = [
    {
      field: 'employee_name', headerName: t("common.Person Name"), minWidth: 220, flex: 0.2, renderCell: (params) => {
        return (
          <Grid style={{ display: "flex", alignItems: "center" }}>
            <Box style={{ padding: '0 8px' }}>{params.row?.first_name} {params.row?.last_name}</Box>
          </Grid>
        )
      }
    },
    {
      field: 'punch_record', headerName: t("common.Punch Record"), flex: 0.7, renderCell: (params) => {
        const res: any[] = [];
        params.row.attendance_status.forEach((element: any, index: number) => {
          const val = element.punch_time.split(":");
          const hour = Number(val[0]);
          const fillHour = (hour: number) => hour < 10 ? `0${hour}` : hour.toString();
          // val[0] = hour <= 12 ? 'AM ' + fillHour(hour) : 'PM ' + fillHour(hour - 12);
          if (element.status === "warning") {
            res.push(<Grid key={index} component={'a'} color={"red"}>{`${val.join(":")};`}</Grid>)
          } else {
            res.push(<Grid key={index} component={'a'}>{`${val.join(":")};`} </Grid>)
          }
        });
        return (
          <Grid>
            {res}
          </Grid>
        )
      }
    }
  ]
  const [gridData, setGridData] = useState<GridData>({ data: [], total: 0 });
  const [pageSize, setPageSize] = useState<number>(5);
  const [page, setPage] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<FilterType>({ date_range: [new Date(), new Date()] });

  const refreshGridData = () => {
    setRefresh(refresh + 1);
  };

  const fetchGridData = () => {
    setLoading(true)
    const { date_range } = params;
    date_range.forEach((element, index) => {
      params.date_range[index] = new Date(element).toISOString().slice(0, 10);
    })
    GenericService.list(ATTEndpoints.ATT_PUNCH_RECORD_URI, {
      current: (page + 1),
      pageSize: pageSize,
      ...params
    }
    ).then((response) => {
      setGridData(response.data);
    }).catch((error) => {
      if (error.response.status < 500) {
        // do something when status is 4xx.
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchGridData()
  }, [page, pageSize, refresh]);
  return (
    <Box sx={{ width: "100%" }}>
      <Grid className={"toolbar-container"}>
        <Box sx={{ display: 'flex', }}>
          <Breadcrumbs separator="" sx={{ height: "32px", p: '8px' }}>
            <Typography sx={{ fontWeight: "400 !important" }}>{t("common.Daily Attendance Record")}</Typography>
          </Breadcrumbs>
        </Box>
      </Grid>
      <Grid className={"toolbar-container"}>
        <Box sx={{ display: 'flex', }}>
          <Typography sx={{ color: '#aeafb0 !important', fontWeight: "400 !important", paddingLeft: "10px" }}>
            {t("common.The red attendance record is mended data")}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Grid
            container={true}
            columnSpacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Grid sx={{ marginLeft: "8px" }} item={true}>
              <Tooltip title={t("common.refresh")}>
                <IconButton onClick={refreshGridData} sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <DataGrid
        columns={columns}
        loading={isLoading}
        pagination={true}
        paginationMode={"server"}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15, 20, 50]}
        page={page}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        rows={gridData?.data}
        rowCount={gridData?.total ? gridData?.total : 0}
        autoHeight={true}
        hideFooterSelectedRowCount={true}
        disableColumnMenu={true}
        style={{ border: 'none' }}
        components={{}}
        componentsProps={{
          pagination: {
            labelRowsPerPage: t("common.datagrid.paginate"),
          }
        }}
        sx={customDataGridStyle}
      />
    </Box>
  )
}
export default EmployeeAttendance
