import React, {Fragment, useEffect, useState} from "react";
import {Box, Paper, Grid, TextField, Button, IconButton, Stack, Tooltip, DialogTitle, Dialog} from "@mui/material";
import {object, TypeOf, z} from "zod";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {ReactComponent as DepartmentGraph} from "@/assets/images/DepartmentGraph.svg"
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import DepartmentService from "@/services/hr/DepartmentService";
import {useSnackbar} from "notistack";
import WebURL from "@/urls";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import {useTranslation} from "react-i18next";
import {Edit} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import EmployeeService from "@/services/hr/EmployeeService";
import EmployeeForm from "@/pages/quick/components/employee/EmployeeForm";


export function EditAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("common.Edit Person")}>
      <IconButton sx={{borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)"}} onClick={() => {
        setInfo(true)
      }}>
        <Edit/>
      </IconButton>
    </Tooltip>
  )
}

export interface EmpEditProps {
  empId: string,
}

const EmployeeEdit: React.FC<EmpEditProps> = (props) => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <EditAction setInfo={setInfo}/>
      </Grid>
      <EditEmpDialog info={info} setInfo={setInfo} empId={props.empId}/>
    </Fragment>
  )
}

const EditEmpDialog = ({info, setInfo, empId}: any) => {
  const {t} = useTranslation();
  const employeeValidate = object({
    code: z.string({required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
      .max(14, t("common.The length of field cannot exceed digits", {number: 14})),
    firstName: z.string({required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
      .max(25, t("common.The length of field cannot exceed digits", {number: 25})),
    lastName: z.string({required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
      .max(25, t("common.The length of field cannot exceed digits", {number: 25})),
    departmentIdOrCode: z.string().optional(),
    dept: z.object({
      id: z.string(),
      name: z.string(),
    }, {required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")}),
  })
  type employeeInput = TypeOf<typeof employeeValidate>;
  const {enqueueSnackbar} = useSnackbar();
  const [saving, setSaving] = React.useState<boolean>(false)
  const employeeForm = useForm<employeeInput>({
    resolver: zodResolver(employeeValidate),
    defaultValues: {
      code: '',
      firstName: '',
      lastName: '',
      dept: {id: '', name: ''},
      departmentIdOrCode: ''
    }
  });
  useEffect(() => {
    if (empId) {
      EmployeeService.retrieve(empId.toString() || "").then((res) => {
        const emp = res.data.data
        employeeForm.reset({
          code: emp?.employeeCode,
          firstName: emp?.firstName,
          lastName: emp?.lastName,
          departmentIdOrCode: emp?.departmentIdOrCode,
          dept: {id: emp?.departmentIdOrCode, name: emp?.department_name}
        })
      })
    }
  }, [])

  const onSubmit = async (event: any) => {
    setSaving(true)
    try {
      employeeForm.unregister("code")
      const values = employeeForm.getValues()
      values.departmentIdOrCode = values.dept.id
      // update employee
      const result = await EmployeeService.update(empId || "", values);
      if (result.status < 400) {
        enqueueSnackbar(t("common.Person updated successfully."), {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
      }
    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
      setInfo(false)
    }
  }

  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      // sx={}
    >
      <DialogTitle>
        {t("common.Edit Person")}
        <IconButton
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <EmployeeForm employeeForm={employeeForm} isEditAction={true}/>
      <Grid style={{'padding':'25px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end" >
          <CancelButton onClick={() => {
            setInfo(false)
          }}/>
          <SavingButton loading={saving} onClick={employeeForm.handleSubmit(onSubmit)}/>
        </Stack>
      </Grid>
    </Dialog>
  )
}

export default EmployeeEdit;
