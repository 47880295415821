import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogTitle, Grid, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {Delete} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DepartmentService from "@/services/hr/DepartmentService";
import {useSnackbar} from "notistack";
import {CancelButton, SaveButton} from "@/components/data-grid/CustomButton";
import SnackbarUtil from "@/utils/SnackbarUtil";
import GenericService from "@/services/GenericService";
import {ATTEndpoints} from "@/services/att/endpoints";

const dialogStyle = {
  '& .MuiPaper-root': {width: '400px', top: '1vh', overflowX: "hidden"},
  '.MuiDialog-paper': {maxWidth: '100vw'},
  overflowX: "hidden",
};

export function DeleteAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("common.Delete Schedule?")}>
      <IconButton sx={{borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)", marginLeft: "8px"}} onClick={() => {
        setInfo(true)
      }}>
        <Delete/>
      </IconButton>
    </Tooltip>
  )
}

export interface ScheduleDeleteProps {
  scheduleIds: Record<string, any>,
}

const ScheduleDelete: React.FC<ScheduleDeleteProps> = (props) => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <DeleteAction setInfo={setInfo}/>
      </Grid>
      <DeleteScheduleDialog info={info} setInfo={setInfo} scheduleIds={props.scheduleIds}/>
    </Fragment>
  )
}

const DeleteScheduleDialog = ({info, setInfo, scheduleIds}: any) => {
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar();
  const onSubmit = async (event: any) => {
    if (scheduleIds.length == 0) {
      SnackbarUtil.error(t("common.Select Schedule first"), {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return;
    }
    try {
      const schedule_list = scheduleIds.map((sche: any) => {
        GenericService.destroy(ATTEndpoints.ATT_SCHEDULE_URI, `${sche}`)
      })
      enqueueSnackbar(t("common.success"), {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      });
    } catch (e) {
      console.log("error===", e)
    } finally {
      setInfo(false)
    }
  }
  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      sx={dialogStyle}
    >
      <DialogTitle>
        {t("common.Delete Schedule?")}
        <IconButton
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <Typography sx={{p: 3}}>
        {t('common.Are you sure to delete these schedules?')}
      </Typography>
      <Grid style={{'padding': '20px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end">
          <CancelButton onClick={() => {
            setInfo(false)
          }}/>
          <SaveButton onClick={onSubmit} children={t('common.confirm')}/>
        </Stack>
      </Grid>
    </Dialog>
  )
}

export default ScheduleDelete;
