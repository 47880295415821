import { Box, Grid, Breadcrumbs, Typography, Drawer, InputAdornment, IconButton } from "@mui/material";
import TextField, { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField';
import { GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SaveButton } from "@/components/data-grid/CustomButton";
import CustomDataGrid from '@/components/data-grid/CustomDataGrid';
import React from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ATTEndpoints } from "@/services/att/endpoints";

export type TimesheetPickerProps = {
  // multi?: boolean;
  renderInput?: (props: MuiTextFieldPropsType) => React.ReactElement;
} & MuiTextFieldPropsType

const customDataGridStyle = {
  '& .MuiDataGrid-columnHeaders': { backgroundColor: '#F0F1F4!important', color: '#304669!important' },
  '.MuiDataGrid-columnHeader:focus-within': { outline: 'none' },
  '.MuiDataGrid-cell': { borderBottom: '0px', color: '#304669' },
  '.MuiDataGrid-cell:focus-within': { outline: 'none!important', color: '#0084d0!important' },
  '.MuiDataGrid-columnSeparator': { display: 'none !important' },
  '.MuiSvgIcon-root': { backgroundColor: 'transparent' },
  '.MuiDataGrid-root': { padding: '8px', borderRadius: '4px' },
  '.MuiDataGrid-row': { borderBottom: '1px solid #E7EBF0', maxHeight: '43px!important', minHeight: '43px!important' },
  '.MuiCheckbox-root': { color: '#D1D1D1' },
  '.MuiDataGrid-cellContent': {
    color: '#304669!important',
    fontFamily: 'Roboto',
    letterSpacing: '0.4px',
    fontSize: '14px'
  },
  '.MuiDataGrid-virtualScrollerRenderZone': { backgroundColor: '#FFFF' },
  '.MuiDataGrid-footerContainer': { display: 'block', width: '100%', border: '0px' },
  '.MuiTablePagination-spacer': { display: 'none' },
  '.MuiTablePagination-actions': { position: 'absolute', right: '0px', backgroundColor: '#FFF' },
  '.MuiTablePagination-displayedRows': { position: 'absolute', right: '90px' },
  '.MuiDataGrid-virtualScroller': { overflow: 'auto !important' },
  '.MuiTablePagination-select': { backgroundColor: '#F0F1F4', borderRadius: '4px!important' },
  '.MuiTablePagination-toolbar': { paddingLeft: '0px' },
  '.MuiIconButton-root.Mui-disabled': { backgroundColor: '#F0F1F4', borderRadius: '0px' },
  // '.MuiIconButton-root': {borderRadius: '0px'},
  '.Mui-checked': { color: 'rgb(54, 201, 109)!important' },
  '.MuiDataGrid-virtualScrollerContent': { height: "calc(100vh - 320px) !important" }
};

function TimesheetPicker(props: TimesheetPickerProps) {
  const { t } = useTranslation();
  const [gridData, setGridData] = useState([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [selectionData, setSelectionData] = useState<Record<string, any>>([]);
  const [open, setOpen] = React.useState(false);
  const { renderInput, value, onChange, ...textFieldProps } = props
  const multi = false
  const valueObj = value as Record<string, any>
  const valueObjs = value as Record<string, any>[]
  let displayValue = ""
  if (multi) {
    const emps = valueObjs.map((emp: any) => `${emp?.name}`)
    displayValue = emps.join(',')
  } else {
    displayValue = valueObj ? `${valueObj?.name}` : ""
  }
  const columns: GridColDef[] = [
    { field: 'name', headerName: t("common.Timesheet Name"), minWidth: 200, flex: 0.2 },
    { field: 'period', headerName: t("common.Unit"), minWidth: 90, flex: 0.1 },
    { field: 'cycle', headerName: t("common.Cycle"), minWidth: 90, flex: 0.1 },
    { field: 'description', headerName: t("common.Description"), type: "string", minWidth: 120, flex: 0.3 },
  ]

  const handleClose = () => {
    setOpen(false);
  }

  const handleSave = (value: any) => {
    setOpen(false);
    if (onChange) {
      if (multi) {
        onChange(value)
      } else {
        onChange(value[0])
      }
    }
  }

  const handleDataGridSave = (value: any) => {
    if (value) {
      setGridData(value)
    }
  }

  return (
    <>
      {
        props.renderInput ?
          <props.renderInput
            onClick={() => setOpen(true)}
            value={displayValue}
            {...textFieldProps}
          />
          :
          <TextField
            onClick={() => setOpen(true)}
            sx={{ width: '100%' }}
            value={displayValue}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton sx={{ marginRight: "-10px" }}>
                  <KeyboardArrowRightIcon />
                </IconButton>
              </InputAdornment>
            }}
            {...textFieldProps}
          />

      }
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        sx={{
          '.MuiPaper-root': {
            height: "calc(100% - 20px)",
            marginTop: "10px",
            marginRight: "5px",
            borderRadius: '4px',
            background: "#F6F6FA",
          }
        }}
      >
        <Box sx={{ padding: '10px', height: '100%', width: '500px' }}>
          <Box sx={{ height: 'calc(100% - 60px)', width: '95%' }}>
            <Grid className={"toolbar-container"}>
              <Box sx={{ display: 'flex', }}>
                <Breadcrumbs separator="" sx={{ height: "32px", p: '8px' }}>
                  <Typography>{t("common.Select timesheet")}</Typography>
                </Breadcrumbs>
              </Box>
            </Grid>
            <Box sx={{ padding: "10px 0px" }}>
              <CustomDataGrid
                uri={ATTEndpoints.ATT_TIMESHEET_URI}
                columns={columns}
                checkboxSelection={multi}
                sx={customDataGridStyle}
                onDataChange={handleDataGridSave}
                onSelectionModelChange={(ids) => {
                  setSelectionModel(ids);
                  setSelectionData(ids.map((id) => gridData.find((row: any) => row.id === id)))
                }}
                selectionModel={selectionModel}
                toolbar={{
                  title: "",
                  filter: false,
                  refresh: false,
                }}
              />
              <Box>
                <SaveButton
                  children={t("common.confirm")}
                  sx={{ width: '100%', padding: "10px", textTransform: "none" }}
                  onClick={() => {
                    handleSave(selectionData)
                  }}
                />
              </Box>
            </Box>
          </Box>

        </Box>
      </Drawer>
    </>
  )
}

export default TimesheetPicker;
