import React, {Fragment, useEffect, useState} from "react";
import {Dialog, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import {object, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useTranslation} from "react-i18next";
import {RemoveRedEyeOutlined} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleService from "@/services/att/services/ScheduleService";
import {DatePicker} from "@mui/x-date-pickers";


export function EditAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("att.schedule.Shift schedule")}>
      <IconButton sx={{borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)"}} onClick={() => {
        setInfo(true)
      }}>
        <RemoveRedEyeOutlined/>
      </IconButton>
    </Tooltip>
  )
}

export interface ScheduleEditProps {
  scheduleId: string,
}

const ScheduleEdit: React.FC<ScheduleEditProps> = (props) => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <EditAction setInfo={setInfo}/>
      </Grid>
      <EditScheduleDialog info={info} setInfo={setInfo} scheduleId={props.scheduleId}/>
    </Fragment>
  )
}

const scheduleSchema = object({
  // assigned_start: z.date(),
  // assigned_end: z.date(),
  employee_name: z.string(),
  timesheet_name: z.string(),
})
type scheduleType = TypeOf<typeof scheduleSchema>;

const EditScheduleDialog = ({info, setInfo, scheduleId}: any) => {
  const {t} = useTranslation();
  const [start_date, setStartDate] = React.useState<Date | null>(null);
  const [end_date, setEndDate] = React.useState<Date | null>(null);

  const scheduleForm = useForm<scheduleType>({
    resolver: zodResolver(scheduleSchema),
  });
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  useEffect(() => {
    ScheduleService.retrieve(scheduleId || "").then((res) => {
      setStartDate(res.data.assigned_start);
      setEndDate(res.data.assigned_end);
      // scheduleForm.setValue('assigned_end', res.data.assigned_end);
      // scheduleForm.setValue('assigned_start', res.data.assigned_start);
      scheduleForm.setValue('employee_name', res.data.employee_name);
      scheduleForm.setValue('timesheet_name', res.data.timesheet_name);
    })
  }, [])

  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      // sx={}
    >
      <DialogTitle>
        {t("att.schedule.Shift schedule")}
        <IconButton
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <Grid style={{minHeight: '200px', backgroundColor: '#fff', borderRadius: '8px', padding: '0 15px 15px 15px'}}>
        <Grid container={true}>
          <Grid container={true} component={"form"} columnSpacing={4} rowSpacing={2}>
            <Grid item xs={12}/>
            <Grid item xs={6}>
              <Typography>
                {t("common.Select person")}
              </Typography>
              <TextField
                hiddenLabel
                fullWidth
                sx={{padding: '10px 10px 10px 0'}}
                InputProps={{
                  readOnly: true,
                }}
                disabled
                {...scheduleForm.register("employee_name")}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {t("common.Select timesheet")}
              </Typography>
              <TextField
                hiddenLabel
                fullWidth
                sx={{padding: '10px 10px 10px 0'}}
                InputProps={{
                  readOnly: true,
                }}
                disabled
                {...scheduleForm.register("timesheet_name")}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {t("common.Start Date")}
              </Typography>
              <DatePicker
                label=""
                value={start_date}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                disabled
                disableMaskedInput
                inputFormat="dd MMM yyyy"
                renderInput={(params) => <TextField fullWidth {...params} />}
                // {...scheduleForm.register("assigned_start")}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {t("common.End Date")}
              </Typography>
              <DatePicker
                label=""
                value={end_date}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                disabled
                disableMaskedInput
                inputFormat="dd MMM yyyy"
                renderInput={(params) => <TextField fullWidth {...params} />}
                // {...scheduleForm.register("assigned_end")}
              />
            </Grid>
            <Grid item xs={6}/>
            <Grid item xs={6}/>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default ScheduleEdit;
