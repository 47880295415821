import React, {Fragment, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogTitle, Grid, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {Sync, Remove} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import EmployeeService from "@/services/hr/EmployeeService";
import {useSnackbar} from "notistack";
import {CancelButton, SaveButton} from "@/components/data-grid/CustomButton";
import SnackbarUtil from "@/utils/SnackbarUtil";
import DeviceService from "@/services/dms/DeviceService";

const dialogStyle = {
  '& .MuiPaper-root': {width: '400px', top: '1vh', overflowX: "hidden"},
  '.MuiDialog-paper': {maxWidth: '100vw'},
  overflowX: "hidden",
};

export function SyncAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("common.Sync Employee to Device")}>
      <IconButton sx={{borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)"}} onClick={() => {
        setInfo(true)
      }}>
        <Sync/>
      </IconButton>
    </Tooltip>
  )
}

export interface EmpSyncProps {
  deviceId: string,
  empIds: Record<string, any>,
}

const EmpSync: React.FC<EmpSyncProps> = (props) => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <SyncAction setInfo={setInfo}/>
      </Grid>
      <SyncEmpDialog info={info} setInfo={setInfo} deviceId={props.deviceId} empIds={props.empIds}/>
    </Fragment>
  )
}

const SyncEmpDialog = ({info, setInfo, deviceId, empIds}: any) => {
  const {t} = useTranslation()
  const onSubmit = async (event: any) => {
    if (empIds.length == 0) {
      SnackbarUtil.error(t("att.schedule.Select person first"), {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return;
    }
    try {
      DeviceService.addPerson(deviceId.toString() || '', empIds).then(
        (result) => {
          SnackbarUtil.success(t('dms.People synced to the device successfully', {No: empIds.length}))
        })
    } catch (e) {
      console.log("error===", e)
    } finally {
      setInfo(false)
    }
  }
  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      sx={dialogStyle}
    >
      <DialogTitle>
        {t("common.Sync Employee to Device")}
        <IconButton
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <Typography sx={{textAlign: "center"}}>
        {t('common.Are you sure to Sync Employee to Device')}
      </Typography>
      <Grid style={{'padding': '20px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end">
          <CancelButton onClick={() => {
            setInfo(false)
          }}/>
          <SaveButton onClick={onSubmit} children={t('common.confirm')}/>
        </Stack>
      </Grid>
    </Dialog>
  )
}

export default EmpSync;
