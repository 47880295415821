import React, {useState, createRef} from "react";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent, Grid,
  ListItemIcon,
  ListItemText,
  MenuItem, Select, Stack,
  TextField, Typography
} from "@mui/material";
import {Language} from "@mui/icons-material";
import {Box} from "@mui/system";
import TranslationUtil from "@/utils/TranslationUtil";
import {CancelButton, SaveButton} from "@/components/data-grid/CustomButton";
import i18n from "@/config/i18n";
import {useTranslation} from "react-i18next";
import {CountryName} from "@/utils/CountryName";
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import {Controller, useForm} from "react-hook-form";
import {TimePicker} from "@mui/x-date-pickers";
import Util from "@/utils/Util";
import {zodResolver} from "@hookform/resolvers/zod";
import {object, TypeOf, z} from "zod";
import {BreakTimeSettingFields} from "@/pages/att/Timesheet/component/PatternSetting";
import {DropzoneDialog, DropzoneArea} from 'material-ui-dropzone'
import Button from '@material-ui/core/Button';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import request from "../../../utils/Request";
import {AuthEndpoints} from "@/services/auth/endpoints";
import {useSnackbar} from "notistack";
import UserService from "@/services/auth/UserService";


export type PatternSettingFields = {
  companyName: string;
}

type LanguageMenuItemProps = {
  hidePopup?: () => Promise<void>;
}

const SupportMenuItem: React.FC<LanguageMenuItemProps> = (props) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const defaultPattern = {
    companyName: "",
    impact: "medium",
    frequency: "seldom",
    contactPerson: "",
    country: "",
    emailAddress: "",
    contactNumber: "",
    summary: "",
    description: "",
    steps2reproduce: "",
    // attachment: "",
  };
  const [country, setCountry] = useState<string>('');
  const country_name = CountryName;
  const {enqueueSnackbar} = useSnackbar();
  const patternSchema = object({
    companyName: z.string(),
    impact: z.string(),
    frequency: z.string(),
    contactPerson: z.string(),
    country: z.string().min(1, t("common.This field is required!")),
    emailAddress: z.string().min(1, t("common.This field is required!")),
    contactNumber: z.string(),
    summary: z.string().min(1, t("common.This field is required!")),
    description: z.string().min(1, t("common.This field is required!")),
    steps2reproduce: z.string().min(1, t("common.This field is required!")),
    attachment: z.any(),
  });
  type PatternInput = TypeOf<typeof patternSchema>;

  const patternSettingForm = useForm<PatternInput>({
    defaultValues: defaultPattern,
    resolver: zodResolver(patternSchema)
  })
  const {
    register,
    trigger,
    setError,
    getValues,
    setValue,
    formState: {errors},
  } = patternSettingForm;

  const onSubmit = async (formData: any) => {
    const formdata = new FormData();
    Object.keys(formData).map((key: any) => {
      if (key == 'attachment') {
        Object.keys(formData[key]).map((att_key: any, index: number) => {
          formdata.append('attachment' + index.toString(), formData[key][index])
        })
      } else {
        formdata.append(key, formData[key])
      }
    })
    await UserService.supportFeedback(formdata).then((response) => {
      if (response.status < 400) {
        enqueueSnackbar("common.Successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
        setOpen(false)
      } else {
        enqueueSnackbar(t('common.Country field error'), {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
      }
    })
  };

  return (
    <Box>
      <MenuItem onClick={async () => {
        await props.hidePopup?.();
        setOpen(true)
      }}>
        <ListItemText>{t("common.Contact us")}</ListItemText>
      </MenuItem>
      <Dialog
        open={open}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        keepMounted
        maxWidth={"md"}
      >
        <DialogTitle>{"NGTeco Help Desk - NGTeco Application Support\n"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={{marginBottom: "20px"}}>
            <Typography variant="h6" gutterBottom>
              Contact us throw E-mail: techsupport@zkteco.in | WhatsApp: +91-9901436424
            </Typography>
          </DialogContentText>
          <Grid container={true} rowSpacing={1} columnSpacing={6} component={"form"}>
            <Grid item xs={12} sx={{p: "0 16px"}}>
              <Grid container={true} rowSpacing={2} columnSpacing={6} component={"form"}>
                <Grid item={true} xs={12}>
                  <NGInputLabel label={t("common.Company Name")}/>
                  <Controller
                    control={patternSettingForm.control}
                    name={"companyName"}
                    render={({field, fieldState}) =>
                      <TextField
                        required={true}
                        size="small"
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || " "}
                        {...field}
                        InputLabelProps={{
                          shrink: !!field.value
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container={true} columnSpacing={2} component={"form"}>
                <Grid item={true} xs={6}>
                  <NGInputLabel label={t("common.Contact Person")}/>
                  <Controller
                    control={patternSettingForm.control}
                    name={"contactPerson"}
                    render={({field, fieldState}) =>
                      <TextField
                        size="small"
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || " "}
                        {...field}
                        InputLabelProps={{
                          shrink: !!field.value
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <NGInputLabel label={t("organ.Country")} required={true}/>
                  <Autocomplete
                    value={country_name.find((country_list) => {
                      return country_list.value === country
                    })}
                    isOptionEqualToValue={(option, value) => {
                      return option.value === value.value
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth={true}
                        size={'small'}
                      />
                    )}
                    getOptionLabel={(option) => {
                      return option.value
                    }}
                    options={country_name}
                    onChange={(event, value) => {
                      patternSettingForm.setValue('country', value?.value || "China")
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container={true} columnSpacing={2} component={"form"}>
                <Grid item={true} xs={6}>
                  <NGInputLabel label={t("common.Email Address")} required={true}/>
                  <Controller
                    control={patternSettingForm.control}
                    name={"emailAddress"}
                    render={({field, fieldState}) =>
                      <TextField
                        required={true}
                        size="small"
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || " "}
                        {...field}
                        InputLabelProps={{
                          shrink: !!field.value
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <NGInputLabel label={t("common.Contact Number")}/>
                  <Controller
                    control={patternSettingForm.control}
                    name={"contactNumber"}
                    render={({field, fieldState}) =>
                      <TextField
                        size="small"
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || " "}
                        {...field}
                        InputLabelProps={{
                          shrink: !!field.value
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container={true} columnSpacing={2} component={"form"}>
                <Grid item={true} xs={12}>
                  <NGInputLabel label={t("common.Summary")} required={true}/>
                  <Controller
                    control={patternSettingForm.control}
                    name={"summary"}
                    render={({field, fieldState}) =>
                      <TextField
                        sx={{width: '50.3vw'}}
                        multiline
                        required={true}
                        minRows={5}
                        maxRows={5}
                        type="text"
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || " "}
                        {...field}
                        InputLabelProps={{
                          shrink: !!field.value
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container={true} columnSpacing={2} component={"form"}>
                <Grid item={true} xs={12}>
                  <NGInputLabel label={t("common.Description")} required={true}/>
                  <Controller
                    control={patternSettingForm.control}
                    name={"description"}
                    render={({field, fieldState}) =>
                      <TextField
                        sx={{width: '50.3vw'}}
                        multiline
                        required={true}
                        minRows={5}
                        maxRows={5}
                        type="text"
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || " "}
                        {...field}
                        InputLabelProps={{
                          shrink: !!field.value
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container={true} columnSpacing={2} component={"form"}>
                <Grid item={true} xs={6}>
                  <NGInputLabel label={t("common.Impact")} required={true}/>
                  <Controller
                    control={patternSettingForm.control}
                    name={"impact"}
                    render={({field, fieldState}) =>
                      <Select
                        {...field}
                        size={"small"}
                        fullWidth={true}
                      >
                        <MenuItem value={"outage"}>{"Extensive/Outage"}</MenuItem>
                        <MenuItem value={"critical"}>{"Significant/ Critical"}</MenuItem>
                        <MenuItem value={"high"}>{"Moderate/ High"}</MenuItem>
                        <MenuItem value={"medium"}>{"Minor/ Medium"}</MenuItem>
                        <MenuItem value={"low"}>{"LOW"}</MenuItem>
                      </Select>
                    }
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <NGInputLabel label={t("common.Frequency")} required={true}/>
                  <Controller
                    control={patternSettingForm.control}
                    name={"frequency"}
                    render={({field, fieldState}) =>
                      <Select
                        {...field}
                        size={"small"}
                        fullWidth={true}
                      >
                        <MenuItem value={"always"}>{"Always"}</MenuItem>
                        <MenuItem value={"usually"}>{"Usually"}</MenuItem>
                        <MenuItem value={"sometimes"}>{"Sometimes"}</MenuItem>
                        <MenuItem value={"occasionally"}>{"Occasionally"}</MenuItem>
                        <MenuItem value={"seldom"}>{"Seldom"}</MenuItem>
                        <MenuItem value={"once"}>{"Once"}</MenuItem>
                      </Select>
                    }
                  />
                </Grid>
              </Grid>
              <Grid container={true} columnSpacing={2} component={"form"}>
                <Grid item={true} xs={6}>
                  <NGInputLabel label={t("common.Steps to Reproduce")} required={true}/>
                  <Controller
                    control={patternSettingForm.control}
                    name={"steps2reproduce"}
                    render={({field, fieldState}) =>
                      <TextField
                        multiline
                        minRows={9.5}
                        maxRows={9.5}
                        type="text"
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || " "}
                        {...field}
                        InputLabelProps={{
                          shrink: !!field.value
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <NGInputLabel label={t("common.Attachment")}/>
                  <Controller
                    control={patternSettingForm.control}
                    name={"attachment"}
                    render={({field, fieldState}) =>
                      <div>
                        <DropzoneArea
                          showPreviews={true}
                          showPreviewsInDropzone={false}
                          useChipsForPreview
                          previewGridProps={{container: {spacing: 1, direction: 'row'}}}
                          previewText="Selected files"
                          onChange={(files) => {
                            setValue("attachment", files)
                          }}
                        />
                      </div>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={() => {
            setOpen(false)
          }}/>
          <SaveButton
            children={TranslationUtil.t("common.confirm")}
            onClick={async () => {
              const isValid = await trigger();
              if (isValid) {
                const data = getValues();
                await onSubmit(data);
              }
            }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  )
}
export default SupportMenuItem
