import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {Dialog, DialogTitle, Grid, IconButton, Tooltip} from "@mui/material";
import {Add} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import NewScheduleQuick from "@/pages/quick/components/schedule/ScheduleForm";

const dialogStyle = {
  '& .MuiPaper-root': { width: '60vw', top: '1vh', overflowX: "hidden"},
  '.MuiDialog-paper': { maxWidth: '100vw' },
  overflowX: "hidden",
};

export function AddAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("att.schedule.Add shift schedule")}>
      <IconButton sx={{borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)"}} onClick={() => {
        setInfo(true)
      }}>
        <Add/>
      </IconButton>
    </Tooltip>
  )
}


const ScheduleAdd = () => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <AddAction setInfo={setInfo}/>
      </Grid>
      <AddTimeSheetDialog info={info} setInfo={setInfo}/>
    </Fragment>
  )
}

const AddTimeSheetDialog = ({info, setInfo}: any) => {
  const {t} = useTranslation()

  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      sx={dialogStyle}
    >
      <DialogTitle>
        {t("att.schedule.Add shift schedule")}
        <IconButton
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <Grid sx={{margin: '20px 0', padding: '20px'}}>
        <Grid
          container
          spacing={2}
        >
          <NewScheduleQuick info={setInfo}/>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default ScheduleAdd;
