import request from "../../../utils/Request";
import {ATTEndpoints} from "../endpoints";
import qs from 'qs';


const list = async (params?: Record<string, any>) => {
  const response = await request.authorizedAxios().get(ATTEndpoints.ATT_TIMECARD_URI, {params, paramsSerializer: params => qs.stringify(params, {indices: false})});
  return response.data;
}

const export_data = async (params: string, data: any, report_type: any) => {
  let url = `${ATTEndpoints.ATT_TIMECARD_URI}export/?${params}`;
  if (report_type == "attendance"){
    url = `${ATTEndpoints.ATT_REPORT_ATTENDANCE_REPORT}export/?${params}`;
  }
  const response = await request.authorizedAxios().post(url, data);
  return response;
}

const download_data = async (params: string, data: any, report_type: any) => {
  let url = `${ATTEndpoints.ATT_TIMECARD_URI}download/?${params}`;
  if (report_type == "attendance") {
    url = `${ATTEndpoints.ATT_REPORT_ATTENDANCE_REPORT}download/?${params}`;
  }
  const response = await request.authorizedAxios().post(url, data, {responseType: "blob"});
  return response;
}

const query_setting = async (report_type: any) => {
  let url = `${ATTEndpoints.ATT_TIMECARD_URI}query_setting/`;
  if (report_type == "attendance") {
    url = `${ATTEndpoints.ATT_REPORT_ATTENDANCE_REPORT}query_setting/`;
  }
  const response = await request.authorizedAxios().get(url);
  return response;
}

const export_setting = async (data: any, report_type: any) => {
  let url = `${ATTEndpoints.ATT_TIMECARD_URI}auto_export_setting/`;
  if (report_type == "attendance"){
    url = `${ATTEndpoints.ATT_REPORT_ATTENDANCE_REPORT}auto_export_setting/`;
  }
  const response = await request.authorizedAxios().post(url, data);
  return response;
}

const TimecardService = {
  list,
  export_data,
  query_setting,
  export_setting,
  download_data,
}
export default TimecardService;
