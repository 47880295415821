import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, Grid, InputAdornment, InputBase, Link, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EmailOutlined, LockOutlined } from "@mui/icons-material";
import MainLayout from "./components/MainLayout";
import UserService from "@/services/auth/UserService";
import { object, TypeOf, z } from "zod";
import { useForm } from "react-hook-form";
import WebURL from "@/urls";
import { useTranslation } from "react-i18next";
import { ReactComponent as LayoutImage } from "@/assets/images/UserLogin.svg"
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import CustomLink from "@/pages/auth/Login/components/CustomLink";

import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import LogoView from "@/pages/auth/Login/components/LogoView";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import CommonUtils from "@/utils/CommonUtils";
import { decode as base64Decode, encode as base64Encode } from "base-64";
import {zodResolver} from "@hookform/resolvers/zod";


const UserLogin: React.FC = () => {
  const native = useNavigate();
  const { t, i18n } = useTranslation();
  const loginSchema = object({
    username: z.string({ required_error: t("common.This field is required!") }).min(1, t("common.This field is required!")),
    password: z.string({ required_error: t("common.This field is required!") }).min(1, t("common.This field is required!")),
    })
  type LoginInput = TypeOf<typeof loginSchema>;
  type Credential = {
    username: string;
    password: string;
  }
  const [loading, setLoading] = useState<boolean>(false);
  const [autoLogin, setAutoLogin] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar()
  const loginForm = useForm<LoginInput>({
    defaultValues: { username: "", password: "" },
    resolver: zodResolver(loginSchema)
  });
  const onLogin = async (credential: Credential) => {
    try {
      setLoading(true)
      const { status, data } = await UserService.login(credential)
      localStorage.setItem("accessToken", data.access);
      localStorage.setItem("refreshToken", data.refresh);
      enqueueSnackbar(t("common.success"), {
        variant: "success",
        autoHideDuration: 1500,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      })
      if (status < 400) {
        if (autoLogin) {
          // auto login set info to cookies
          CommonUtils.setCookie("autoUserInfo", base64Encode(JSON.stringify(credential)), 5);
        } else {
          CommonUtils.setCookie("autoUserInfo", base64Encode(JSON.stringify(credential)), 0);
        }
        native(WebURL.AUTH_USER_COMPANY_SELECTION);
      } else {
        native(WebURL.AUTH_USER_LOGIN);
      }
    } finally {
      setLoading(false)
    }
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const ariaLabel = { 'aria-label': 'description' };

  useEffect(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    const _userInfo = CommonUtils.getCookie("autoUserInfo");
    if (_userInfo !== "") {
      const userInfo: Credential = JSON.parse(base64Decode(_userInfo));
      if (userInfo.username && userInfo.password) {
        loginForm.setValue("username", userInfo.username);
        loginForm.setValue("password", userInfo.password);
        setAutoLogin(true);
      }
    }
  }, [])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

    const preventDefault = (event: any) => {
    event.preventDefault();
  }

  return (
    <MainLayout image={<LayoutImage />}>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid #CAD0D9",
        marginTop: "9vh",
        height: "76vh",
        minHeight: "480px",
        width: '34vw',
        borderRadius: "20px",
        position: "relative",
      }}>
        <LogoView />
        <Box component={"form"} autoComplete="off" style={{ width: '30vw' }}>
          <Stack spacing={2}>
            <ControlledTextFiled
              hookForm={loginForm}
              name={'username'}
              label={t("login.email")}
              fullWidth
              required
              placeholder={t("login.email")}
              inputProps={ariaLabel}
            />
            <ControlledTextFiled
              autoComplete="new-password"
              hookForm={loginForm}
              name={'password'}
              label={t("login.password")}
              fullWidth
              required
              onContextMenu={preventDefault}
              onPaste={preventDefault}
              onCopy={preventDefault}
              onCut={preventDefault}
              type={showPassword ? 'text' : 'password'}
              placeholder={t("login.password")}
              inputProps={ariaLabel}
              InputProps={{
                startAdornment: <InputAdornment position={"start"}><LockOutlined /></InputAdornment>,
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                  </IconButton>
                </InputAdornment>
              }}
            />
            <Grid sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
              <FormControlLabel
                label={
                  <Box>
                    <Typography sx={{ fontSize: "14px" }} >
                      {t("login.Remember account in 5 day")}
                    </Typography>
                  </Box>
                }
                control={
                  <Checkbox
                    color="primary"
                    checked={autoLogin}
                    onChange={(e) => {
                      setAutoLogin(e.target.checked);
                    }} />
                }
              />
              <Link
                underline={"none"}
                onClick={() => native(WebURL.AUTH_FORGOT_PASSWORD)}
                sx={{
                  cursor: "pointer",
                  padding: "0 8px",
                }}
              >
                <Typography sx={{ fontSize: "14px" }}>{t("signUp.Forgot password?")}</Typography>
              </Link>
            </Grid>
            <Box>
              <LoadingButton
                type={"submit"}
                fullWidth={true}
                variant={"contained"}
                color={"primary"}
                loading={loading}
                sx={{ textTransform: "capitalize" }}
                onClick={ loginForm.handleSubmit(async () => {
                  await onLogin(loginForm.getValues())
                })}
              >
                {t("login.login")}
              </LoadingButton>
            </Box>
            <Grid sx={{
              display: "inline-flex",
              justifyContent: "center",
              paddingBottom: 1,
              bottom: 0,
              position: "absolute",
              textAlign: 'center',
              width: '30vw',
            }}>
              <Box><Typography sx={{ fontSize: "14px" }}>{t("login.Don't have an account? Please")}</Typography></Box>
              <CustomLink
                label={t("signUp.Sign up")}
                onClick={() => native(WebURL.AUTH_SIGN_UP)}
              />
            </Grid>
          </Stack>
        </Box>
      </Box>
    </MainLayout>
  )
};
export default UserLogin
