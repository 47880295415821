import React, {Fragment, useState} from "react";
import SnackbarUtil from '@/utils/SnackbarUtil';
import {useTranslation} from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import DeviceService from '@/services/dms/DeviceService';
import LoadingButton from '@mui/lab/LoadingButton';
import {Delete} from "@mui/icons-material"

const punchRecordStyle = {
  '& .MuiPaper-root': {width: '400px', top: '-10vh'},
  '.MuiDialog-paper': {maxWidth: '60vw'},
};

export function DeleteAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("common.Delete Daylight Saving Time")}>
      <IconButton sx={{borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)"}} onClick={() => {
        setInfo(true)
      }}>
        <Delete/>
      </IconButton>
    </Tooltip>
  )
}

export interface DeviceIdsProps {
  deviceIds: any,
}

const DeleteDaylightSavingTime = (props: DeviceIdsProps) => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <DeleteAction setInfo={setInfo}/>
      </Grid>
      <DeleteDaylightSavingTimeContent info={info} setInfo={setInfo} deviceIds={props.deviceIds}/>
    </Fragment>
  )
}

const DeleteDaylightSavingTimeContent = ({info, setInfo, deviceIds}: any) => {
  const {t} = useTranslation()
  const [loading, setLoading] = React.useState(false);

  const DeleteSaveDaylightTime = () => {
    if (deviceIds.length == 0) {
      SnackbarUtil.error(t("common.Select device first"), { anchorOrigin: { vertical: 'top', horizontal: 'center' } })
      return;
    }
    if (deviceIds) {
      deviceIds.map((dev: any) => {
          DeviceService.DeleteDaylightSavingTime(dev).then(
            (result) => {
              SnackbarUtil.success('Success.')
            }
          );
        }
      )
    }
    setInfo(false)
  }
  return (
    <Dialog
      open={info}
      onClose={() => {
        setInfo(false);
      }}
      sx={punchRecordStyle}
    >
      <DialogTitle>
        {t("common.Delete Daylight Saving Time")}
      </DialogTitle>
      <DialogContent sx={{lineHeight: '25px', color: 'gray'}}>
        <Typography>
          {t("common.are you sure to delete these devices daylight saving time?")}
        </Typography>
        <DialogActions>
          <LoadingButton
            id='ConfirmButton'
            loading={loading}
            onClick={() => (DeleteSaveDaylightTime())}
            variant={"contained"}>{t('common.confirm')}</LoadingButton>
          <Button id='CancelButton' onClick={() => {
            setInfo(false);
          }}>{t('common.cancel')}</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteDaylightSavingTime;
