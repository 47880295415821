import request from "../../utils/Request";
import {HREndpoints} from "./endpoints";

const list = async (params?: Record<string, any>) => {
  const response = await request.authorizedAxios().get(HREndpoints.HR_EMPLOYEE_URI, params);
  return response.data;
}

const add = async (data: any) => {
  const response = await request.authorizedAxios().post(HREndpoints.HR_EMPLOYEE_URI, data);
  return response;
}

const retrieve = async (id: string) => {
  const response = await request.authorizedAxios().get(`${HREndpoints.HR_EMPLOYEE_URI}${id}/`);
  return response;
}

const update = async (id: string, data: any) => {
  const response = await request.authorizedAxios().put(`${HREndpoints.HR_EMPLOYEE_URI}${id}/`, data);
  return response;
}

const destroy = async (id: string) => {
  const response = await request.authorizedAxios().delete(`${HREndpoints.HR_EMPLOYEE_URI}${id}/`);
  return response;
}

const resign = async (id: string, data?: any) => {
  const response = await request.authorizedAxios().put(`${HREndpoints.HR_EMPLOYEE_URI}${id}/resign/`, data);
  return response;
}

const EmployeeService = {
  list,
  destroy,
  retrieve,
  update,
  add,
  resign,
}
export default EmployeeService;
