import React, {useEffect, useState} from "react";
import {Box, Checkbox, FormControlLabel, Grid, Menu, MenuItem, Tooltip,} from "@mui/material";
import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import {useTranslation} from "react-i18next";
import {ReactComponent as IconAnnouncement} from '@/assets/icons/MenuAnnouncement.svg';
import Link from "@mui/material/Link";
import PopupState, {bindPopover} from 'material-ui-popup-state';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import {ReactComponent as AppStore} from "@/assets/images/App Store.svg"
import {ReactComponent as GooglePlay} from "@/assets/images/Google Play.svg"
import {LoadingButton} from "@mui/lab";
import UserService from "@/services/auth/UserService";

const Announcement: React.FC = () => {
  const {t} = useTranslation();
  const [selectValue, setSelectValue] = useState(false);
  const [openApp, setOpenApp] = useState(false);
  const [openTimesheet, setOpenTimesheet] = useState(false);
  const popupState = usePopupState({variant: "popover", popupId: "announcement"});
  const get_data = {
    select: selectValue,
    method: 'get'
  }
  useEffect(() => {
    UserService.firstLoginByTimesheet(get_data).then((response) => {
      const is_open = response.data.open;
      setOpenTimesheet(is_open)
      setOpenApp(false)
      popupState.isOpen = false
      const is_select = response.data.select;
      setSelectValue(is_select)
    })
  }, [])

  const handleSave = (value: any) => {
    const post_data = {
      select: selectValue,
      method: 'post'
    }
    setOpenTimesheet(false)
    UserService.firstLoginByTimesheet(post_data).then((response) => {
      console.log()
    })
  }
  return (
    <Box>
      <Tooltip title={t("common.Announcement")}>
        <Grid
          style={{display: "flex", alignItems: "center", padding: "0 16px", marginTop: "4px"}}
          {...bindTrigger(popupState)}
        >
          <IconAnnouncement/>
        </Grid>
      </Tooltip>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 70,
                horizontal: "right",
              }}
              open={openApp}
              // transformOrigin={{
              //   vertical: 55,
              //   horizontal: "right",
              // }}
            >
              <Grid>
                <Typography sx={{p: 2, width: "480px", backgroundColor: "rgb(255,255,255)"}}>
                  <h3 style={{color: "#0084d0"}}>Good News !</h3>
                  <Box>
                    We have already released the NGTeco Office mobile application!
                  </Box>
                  <Box>
                    You can search 'NGTeco Office' in Google Play or App Store .
                  </Box>
                  <Box>
                    You can also scan these QR code to download mobile app .
                  </Box>
                  <Box>
                    <Grid sx={{marginTop: "20px"}}>
                      <Grid>
                        <Grid sx={{position: "absolute", left: "60%"}}>
                          <AppStore/>
                        </Grid>
                        <Grid sx={{marginTop: "80px", position: "absolute", left: "60%"}}>
                          <Box>App Store</Box>
                        </Grid>
                      </Grid>
                      <Grid>
                        <Grid sx={{position: "absolute", left: "25%"}}>
                          <GooglePlay/>
                        </Grid>
                        <Grid sx={{marginTop: "80px", position: "absolute", left: "24%"}}>
                          <Box>Google Play</Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    {/*<Grid sx={{backgroundColor: "#0084D0", opacity: 0.7}}>*/}
                    <Grid sx={{marginTop: "150px"}}>
                      <LoadingButton
                        size={'small'}
                        onClick={() => {
                          setOpenApp(false)
                        }}
                        variant={"contained"}
                        sx={{padding: "3px", textTransform: "capitalize", width: "20%", marginLeft: "80%"}}
                      >{t("common.confirm")}
                      </LoadingButton>
                    </Grid>
                  </Box>
                </Typography>
              </Grid>
            </Popover>
          </div>
        )}
      </PopupState>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 70,
                horizontal: "right",
              }}
              open={openTimesheet}
              // transformOrigin={{
              //   vertical: 55,
              //   horizontal: "right",
              // }}
            >
              <Grid>
                <Typography sx={{p: 2, width: "480px", backgroundColor: "rgb(255,255,255)"}}>
                  <h3 style={{color: "#0084d0"}}>Good News !</h3>
                  <Box>
                    We have released new Timesheet : Normal Timesheet and Flexible Timesheet.
                  </Box>
                  <Box>
                    You can try it in the Timesheet feature.
                  </Box>
                  <Box>
                    Note: Existing Timesheets will not be modified and will use the
                    old attendance calculation method.
                  </Box>
                  <Box>
                    {/*<Grid sx={{backgroundColor: "#0084D0", opacity: 0.7}}>*/}
                    <Grid sx={{marginTop: "20px"}}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectValue}
                            sx={{marginLeft: '20px'}}
                            onChange={(event) => {
                              setSelectValue(event.target.checked)
                            }}/>
                        }
                        label={t("common.Do not display again")}
                      />
                      <LoadingButton
                        size={'small'}
                        onClick={() => {
                          handleSave(selectValue)
                        }}
                        variant={"contained"}
                        sx={{padding: "3px", textTransform: "capitalize", width: "20%", marginLeft: "50px"}}
                      >{t("common.confirm")}
                      </LoadingButton>
                    </Grid>
                  </Box>
                </Typography>
              </Grid>
            </Popover>
          </div>
        )}
      </PopupState>
      <Menu
        sx={{mt: '45px'}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted={true}
        {...bindMenu(popupState)}
      >
        <Grid sx={{p: 1, color: '#0084d0'}}>{t('common.Announcement Note')}</Grid>
        <MenuItem><Link sx={{color: "#0c0c0c"}}
                        onClick={() => {
                          setOpenApp(false)
                          setOpenTimesheet(true)
                          popupState.isOpen = false
                        }}>{t('common.New Timesheet')}</Link>
        </MenuItem>
        <MenuItem><Link sx={{color: "#0c0c0c"}}
                        onClick={() => {
                          setOpenApp(true)
                          setOpenTimesheet(false)
                          popupState.isOpen = false
                        }}>{t('common.New Mobile Apps')}</Link>
        </MenuItem>
      </Menu>
    </Box>
  )
}
export default Announcement
