import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  IconButton,
  Stack,
  SvgIcon,
  Paper,
  Typography
} from "@mui/material";
import { ReactComponent as DefaultOrganization } from '@/assets/images/DefaultOrganization.svg';
import { useForm } from 'react-hook-form';
import { forwardRef, useEffect, useState } from "react";
import CompanyService from "@/services/auth/CompanyService";
import {useDispatch, useSelector} from "react-redux";
import { useSnackbar } from "notistack";
import Uploady, { useBatchAddListener } from "@rpldy/uploady";
import UploadPreview from "@rpldy/upload-preview";
import { asUploadButton } from "@rpldy/upload-button";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import React from "react";
import {useTranslation} from "react-i18next";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import OrganDelete from "@/pages/company/OrganDelete";
import { ReactComponent as IconDelete } from '@/assets/icons/IconDelete.svg';
import { ReactComponent as IconEditorList } from '@/assets/icons/IconEditorList.svg';
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import {zodResolver} from "@hookform/resolvers/zod";
import {object, TypeOf, z} from "zod";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import {obtainCompanyInfo} from "@/store/slices/App";


const OrganView: React.FC = () => {
  const { t } = useTranslation();
  const creationSchema = object({
    name: z.string().min(1, t("common.This field is required!")),
    code: z.string().min(1, t("common.This field is required!")),
  })
  type creationInput = TypeOf<typeof creationSchema>;
  const organForm = useForm<creationInput>({
    resolver: zodResolver(creationSchema)
  });
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = organForm;
  const [country, setCountry] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const [destroy, setDestroy] = useState<boolean>(false);
  const [companyLogo, setCompanyLogo] = useState<string>("");
  const company = useSelector((state: any) => state.app.company);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState<any>();
  const name_text = t("organ.name text tip")
  const code_text = t("organ.code text tip")
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    setLoading(true);
    const values = getValues();
    const data = { country: country, name: values.name, companyLogo: imageFile, id: company.id};
    try {
      const result =  await CompanyService.update(data)
      if (result.code == "UASI0007") {
        dispatch<any>(obtainCompanyInfo());
        enqueueSnackbar(t("common.Organization updated successfully."), {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
        setEdit(false);
      }
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    CompanyService.companyInfo().then(res => {
      setImageFile(undefined);
      setCompanyLogo(res.data.imagePreSignedURL)
      setValue("name", res.data.name);
      setValue("code", res.data.code);
      setCountry(res.data?.country || "");
    });
  }, [edit])

  const ImageView = (props: any) => {
    return (
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          height={props.height}
          image={companyLogo}
          alt="Company Logo"
        />
      </Card>
    )
  };

  const MyPreview = (props: any) => {
    return (
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          height="200"
          image={props.url}
          alt="Company Logo"
        />
      </Card>
    )
  };

  const CustomUploadButton = asUploadButton(forwardRef(
    (props: any, ref: any) => {
      useBatchAddListener((batch) => {
        console.log(batch.items[0].file)
        if (batch.items[0].file.size > 3000000) {
          enqueueSnackbar(t("common.Photos size up to maximum 3 MB"), {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
        }
        setImageFile(batch.items[0].file)
      });
      return <Button component="label" variant="contained" sx={{width: '90%', textTransform: "none"}} {...props} />
    }
  ));

  return (
    <>
      {
        edit ?
          <CustomBreadcrumbs record={[
            { label: t("common.Organization Profile"), onClick: () => setEdit(false)},
            { label: t("common.Editor")},
          ]} />
          :
          <>
            <CustomBreadcrumbs record={[{ label: t("common.Organization Profile") }]} />
            <Box>
              <IconButton onClick={() => setDestroy(true)} sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)", marginLeft: '8px' }}>
                <SvgIcon component={IconDelete} />
              </IconButton>
              <IconButton onClick={() => setEdit(true)} sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)", marginLeft: '8px' }}>
                <SvgIcon component={IconEditorList} />
              </IconButton>
            </Box>
          </>
      }
    <Paper elevation={0} sx={{margin: '10px 10px'}}>
      <Grid container sx={{ p: '20px' }}>
        <Grid lg={5} item sx={{ height: '45vh', display: 'flex', justifyContent: 'start', padding: '8px' }}>
          <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid #AEAFB0', width: '90%', height: '100%', lineHeight: '50px', minWidth: '200px' }}>
            <Uploady >
              <Box sx={{ height: '200px' }}>
                {
                  imageFile ?
                    <UploadPreview
                      PreviewComponent={MyPreview}
                    />
                    :
                    companyLogo ? <ImageView height="200" /> : <DefaultOrganization />
                }
              </Box>
              {
                edit ?
                  <>
                    <Box>
                      {imageFile ?<Grid></Grid> :<Typography>{t("common.Photos size up to maximum 3 MB")}</Typography>}
                    </Box>
                    <CustomUploadButton>{t("organ.Add photos")}</CustomUploadButton>
                  </>
                  : <></>
              }
            </Uploady>
          </Grid>
        </Grid>
        <Grid lg={7} item sx={{marginTop: "15px"}}>
          <ControlledTextFiled
              hookForm={organForm}
              name={'name'}
              tooltip={name_text}
              label={t("organ.Organization Name")}
              fullWidth
              disabled={!edit}
              required
            />
            <ControlledTextFiled
              hookForm={organForm}
              name={'code'}
              label={t("organ.Organization Code")}
              tooltip={code_text}
              disabled={true}
              fullWidth
              required
            />
            <Grid>
              <NGInputLabel label={t("signUp.Choose your country")}/>
              {
                edit ?
                  <Select
                    sx={{ width: '100%'}}
                    value={country}
                    defaultValue={"USA"}
                    onChange={e => {
                      setCountry(e.target.value);
                    }}
                  >
                    <MenuItem value={"USA"}>{t("common.America")}</MenuItem>
                    <MenuItem value={"JPN"}>{t("common.Japan")}</MenuItem>
                    <MenuItem value={"GER"}>{t("common.Germany")}</MenuItem>
                    <MenuItem value={"FRA"}>{t("common.France")}</MenuItem>
                    <MenuItem value={"SPA"}>{t("common.Spain")}</MenuItem>
                    <MenuItem value={"UK"}>{t("common.United Kingdom")}</MenuItem>
                    <MenuItem value={"ITA"}>{t("common.Italy")}</MenuItem>
                    <MenuItem value={"CAN"}>{t("common.Canada")}</MenuItem>
                    <MenuItem value={"MEX"}>{t("common.Mexico")}</MenuItem>
                    <MenuItem value={"MAL"}>{t("common.Malaysia")}</MenuItem>
                    <MenuItem value={"THA"}>{t("common.Thailand")}</MenuItem>
                    <MenuItem value={"PHI"}>{t("common.Philippines")}</MenuItem>
                  </Select>
                  :
                  <Select
                    sx={{ width: '100%' }}
                    value={country}
                    disabled
                  >
                    <MenuItem value={"USA"}>{t("common.America")}</MenuItem>
                    <MenuItem value={"JPN"}>{t("common.Japan")}</MenuItem>
                    <MenuItem value={"GER"}>{t("common.Germany")}</MenuItem>
                    <MenuItem value={"FRA"}>{t("common.France")}</MenuItem>
                    <MenuItem value={"SPA"}>{t("common.Spain")}</MenuItem>
                    <MenuItem value={"UK"}>{t("common.United Kingdom")}</MenuItem>
                    <MenuItem value={"ITA"}>{t("common.Italy")}</MenuItem>
                    <MenuItem value={"CAN"}>{t("common.Canada")}</MenuItem>
                    <MenuItem value={"MEX"}>{t("common.Mexico")}</MenuItem>
                    <MenuItem value={"MAL"}>{t("common.Malaysia")}</MenuItem>
                    <MenuItem value={"THA"}>{t("common.Thailand")}</MenuItem>
                    <MenuItem value={"PHI"}>{t("common.Philippines")}</MenuItem>
                  </Select>
              }
            </Grid>
        </Grid>
      </Grid>
    </Paper>
      {
        edit ?
          <Grid item xs={4} sx={{ marginLeft: "61.5%", marginTop: "70px"}}>
            <Stack spacing={3} direction={"row"} justifyContent="flex-end" >
              <CancelButton onClick={() => setEdit(false)}/>
              <SavingButton loading={loading} onClick={handleSubmit(onSubmit)}/>
            </Stack>
          </Grid>
          : <></>
      }
      <OrganDelete open={destroy} setOpen={setDestroy}
      ImageView={companyLogo ? <ImageView height="70" /> : <DefaultOrganization height="70" width="70" />}/>
    </>
  )
}

export default OrganView;
