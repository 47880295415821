import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {Grid, Dialog, Tooltip, Stack} from "@mui/material";
import {DialogTitle, IconButton} from "@mui/material";
import {Add} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import {object, TypeOf, z} from "zod";
import DepartmentService from "@/services/hr/DepartmentService";
import {useSnackbar} from "notistack";
import {useForm, UseFormReturn} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";

export function AddAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("common.New Department")}>
      <IconButton sx={{borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)"}} onClick={() => {
        setInfo(true)
      }}>
        <Add/>
      </IconButton>
    </Tooltip>
  )
}


const DepartmentAdd = () => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <AddAction setInfo={setInfo}/>
      </Grid>
      <AddDeptDialog info={info} setInfo={setInfo}/>
    </Fragment>
  )
}

const AddDeptDialog = ({info, setInfo}: any) => {
  const {t} = useTranslation()
  const departmentValidate = object({
    code: z.string().min(1, t("common.This field is required!"))
      .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
    name: z.string().min(1, t("common.This field is required!"))
      .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
  })
  const [saving, setSaving] = React.useState<boolean>(false)
  type DepartmentForm = TypeOf<typeof departmentValidate>;
  const departmentForm = useForm<DepartmentForm>({
    resolver: zodResolver(departmentValidate),
    defaultValues: {
      code: '',
      name: '',
    }
  });
  const {enqueueSnackbar} = useSnackbar();
  const onSubmit = async (event: any) => {
    setSaving(true)
    try {
      const result = await DepartmentService.add(departmentForm.getValues());
      if (result.status < 400) {
        enqueueSnackbar(t("common.Department added successfully."), {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
      }
    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
      setInfo(false)
    }
  }
  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      // sx={}
    >
      <DialogTitle>
        {t("common.New Department")}
        <IconButton
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <Grid sx={{margin: '10px 0', padding: '10px'}}>
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <ControlledTextFiled
              hookForm={departmentForm}
              label={t("hr.department.Department name")}
              name="name"
              fullWidth
              required
              placeholder={t("hr.department.Department name")}
            />
            <ControlledTextFiled
              hookForm={departmentForm}
              label={t("hr.department.Department code")}
              name="code"
              fullWidth
              required
              placeholder={t("hr.department.Department code")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{'padding': '20px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end">
          <CancelButton onClick={() => {
            setInfo(false)
          }}/>
          <SavingButton loading={saving} onClick={departmentForm.handleSubmit(onSubmit)}/>
        </Stack>
      </Grid>
    </Dialog>
  )
}

export default DepartmentAdd;
