import {Box, Breadcrumbs, Grid, Paper, Typography} from "@mui/material";
import React from "react";
import EmployeeAttendance from "./components/EmployeeAttendance";
import DeviceList from "./components/DeviceList";
import Organization from '@/assets/images/Organization.png';
import { useSelector } from "react-redux";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {useTranslation} from "react-i18next";

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const company = useSelector((state: any) => state.app.company);
  return (
    <Box>
      <Grid className={"toolbar-container"}>
        <CustomBreadcrumbs record={[{ label: t("common.Dashboard") }]} />
      </Grid>
      <Grid container={true}>
        <Grid item={true}>
          <Grid className={"toolbar-container"}>
            <Breadcrumbs sx={{ height: "32px", p: '8px' }}>
              <Typography sx={{ fontWeight: "400 !important" }}>{t("common.Current Organization")}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid >
            <Paper sx={{ width: "640px", height: "280px", backgroundImage: `url(${Organization})` }} elevation={0}>
              <Typography sx={{ fontSize: '20px', color: "#fff", position: 'relative', bottom: "-217px", left: "50px" }}>
                {company?.name ? company.name : "None"}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid item={true} xs>
          <Grid className={"toolbar-container"}>
            <DeviceList />
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <EmployeeAttendance />
        </Grid>
      </Grid>

    </Box>
  )
}
export default Dashboard
