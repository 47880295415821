import { Box, Grid, Breadcrumbs, Typography, Badge, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import GenericService from "@/services/GenericService";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { ATTEndpoints } from "@/services/att/endpoints";
import { Refresh } from '@mui/icons-material';
import { DMSEndpoints } from "@/services/dms/endpoints";
import TableContainer from '@mui/material/TableContainer';
import { ReactComponent as DeviceIcon } from '@/assets/images/Device.svg';
import { useTranslation } from "react-i18next";


type GridData = {
  data: any;
  total: number;
  num_pages: number;
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
  borderRadius: '10px'
}));


const DeviceList: React.FC = () => {
  const { t } = useTranslation();
  const [gridData, setGridData] = useState<GridData>({ data: [], total: 0, num_pages: 0 });
  const [pageSize, setPageSize] = useState<number>(16);
  const [refresh, setRefresh] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);

  const refreshGridData = () => {
    setPage(0)
    setRefresh(refresh + 1);
  };

  const handleScroll = (event: any) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position < 1) {
      appendGridData()
    }
  };

  const fetchGridData = () => {
    setLoading(true)
    GenericService.list(DMSEndpoints.DMS_DEVICE_URI, { current: (page + 1), pageSize: pageSize }).then((response) => {
      // response.data =
      setGridData(response.data);
    }).catch((error) => {
      if (error.response.status < 500) {
        // do something when status is 4xx.
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const appendGridData = () => {
    setLoading(true)
    const newPage = page + 1
    if (newPage >= gridData.num_pages) {
      return
    }

    GenericService.list(ATTEndpoints.ATT_EMPLOYEE_PRESENT_STATUS_URI, { current: (newPage + 1), pageSize: pageSize }).then((response) => {
      setPage(newPage);
      response.data.data = gridData.data.concat(response.data.data)
      setGridData(response.data);
    }).catch((error) => {
      // setPage(page - 1);
      if (error.response.status < 500) {
        // do something when status is 4xx.
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchGridData()
  }, [refresh]);

  return (
    <Box sx={{ width: "100%", height: "350px", marginLeft: "20px" }}>
      <Grid sx={{ paddingBottom: "5px" }} className={"toolbar-container"}>
        <Box sx={{ display: 'flex', }}>
          <Breadcrumbs separator="" sx={{ height: "32px", p: '8px' }}>
            <Typography sx={{ fontWeight: "400 !important" }}>{t("common.Device Overview")}</Typography>
          </Breadcrumbs>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Grid
            container={true}
            columnSpacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Grid sx={{ marginLeft: "8px" }} item={true}>
              <Tooltip title={t("common.refresh")}>
                <IconButton onClick={refreshGridData} sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <TableContainer onScroll={handleScroll} sx={{ backgroundColor: "transparent", height: "300px", boxShadow: "none" }} component={Paper}>
        <Grid>
          <Box
            sx={{
              p: 2,
              display: 'grid',
              gridTemplateColumns: { md: '1fr 1fr' },
              gap: 2,
              padding: "8px",
            }}
          >
            {gridData?.data.map((item: any) => (
              <Item
                sx={{
                  display: 'flex',
                  alignItems: "center",
                  position: "relative",
                }}
                key={item.id} elevation={0}
              >
                <Grid item sx={{ padding: "10px" }}>
                  <Badge
                    color="success"
                    badgeContent=""
                    variant="dot"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    sx={{
                      '.MuiBadge-badge': {
                        backgroundColor: item.status === '1' ? "#20cf61" : "#ef5656",
                        bottom: "7px", right: "7px", border: `3px solid #fff`,
                        height: "15px",
                        width: "15px",
                        minWidth: "15px",
                        borderRadius: "15px",
                      }
                    }}
                  >
                    <DeviceIcon />
                  </Badge>
                </Grid>
                <Grid>
                  <Typography>{item.alias}</Typography>
                  <Typography>{item.sn}</Typography>
                </Grid>
              </Item>
            ))}
          </Box>
        </Grid>
      </TableContainer>
    </Box>
  )

}

export default DeviceList;
