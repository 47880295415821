import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material"
import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {Download} from "@mui/icons-material";

type ExportProps = {
  setExportFormat: (format: "xlsx" | "csv") => void
}

const ExportAction = (props: ExportProps) => {
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChoice = (event: any) => {
    props.setExportFormat(event.target.innerText)
    handleClose()
  }

  return (
    <Fragment>
      <Tooltip arrow title={t("common.Download")} onClick={handleClick}>
        <IconButton id='ExportIconButton' sx={{borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)"}}>
          <Download/>
        </IconButton>
      </Tooltip>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={handleChoice}>xlsx</MenuItem>
        <MenuItem onClick={handleChoice}>csv</MenuItem>
      </Menu>
    </Fragment>
  )
}

export default ExportAction
