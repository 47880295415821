import React, {useEffect, useState, useRef} from "react";
import {Box, Grid, IconButton, Breadcrumbs, Typography, Badge, Paper, Checkbox, Tooltip, Drawer} from "@mui/material";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {useNavigate, useParams, useLocation} from "react-router-dom";
import GenericService from "@/services/GenericService";
import {styled} from '@mui/material/styles';
import SnackbarUtil from "@/utils/SnackbarUtil";
import {DMSEndpoints} from "@/services/dms/endpoints";
import DeviceService from '@/services/dms/DeviceService';
import {LoadingButton} from "@mui/lab";
import {CancelButton, SaveButton} from "@/components/data-grid/CustomButton";
import {useTranslation} from "react-i18next";
import {Add} from "@mui/icons-material";
import CustomDataGrid from "@/components/data-grid/CustomDataGrid";
import {HREndpoints} from "@/services/hr/endpoints";
import {GridColDef, GridSelectionModel} from "@mui/x-data-grid";

const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: 70,
  lineHeight: '20px',
}));

const customDataGridStyle = {
  '& .MuiDataGrid-columnHeaders': {backgroundColor: '#F0F1F4!important', color: '#304669!important'},
  '.MuiDataGrid-columnHeader:focus-within': {outline: 'none'},
  '.MuiDataGrid-cell': {borderBottom: '0px', color: '#304669'},
  '.MuiDataGrid-cell:focus-within': {outline: 'none!important', color: '#0084d0!important'},
  '.MuiDataGrid-columnSeparator': {display: 'none !important'},
  '.MuiSvgIcon-root': {backgroundColor: 'transparent'},
  '.MuiDataGrid-root': {padding: '8px', borderRadius: '4px'},
  '.MuiDataGrid-row': {borderBottom: '1px solid #E7EBF0', maxHeight: '43px!important', minHeight: '43px!important'},
  '.MuiCheckbox-root': {color: '#D1D1D1'},
  '.MuiDataGrid-cellContent': {
    color: '#304669!important',
    fontFamily: 'Roboto',
    letterSpacing: '0.4px',
    fontSize: '14px'
  },
  '.MuiDataGrid-virtualScrollerRenderZone': {backgroundColor: '#FFFF'},
  '.MuiDataGrid-footerContainer': {display: 'block', width: '100%', border: '0px'},
  '.MuiTablePagination-spacer': {display: 'none'},
  '.MuiTablePagination-actions': {position: 'absolute', right: '0px', backgroundColor: '#FFF'},
  '.MuiTablePagination-displayedRows': {position: 'absolute', right: '90px'},
  '.MuiDataGrid-virtualScroller': {overflow: 'auto !important'},
  '.MuiTablePagination-select': {backgroundColor: '#F0F1F4', borderRadius: '4px!important'},
  '.MuiTablePagination-toolbar': {paddingLeft: '0px'},
  '.MuiIconButton-root.Mui-disabled': {backgroundColor: '#F0F1F4', borderRadius: '0px'},
  // '.MuiIconButton-root': {borderRadius: '0px'},
  '.Mui-checked': {color: 'rgb(54, 201, 109)!important'},
  '.MuiDataGrid-virtualScrollerContent': {height: "calc(100vh - 320px) !important"}
};

export const EmployeeListButtonDrawer: React.FC = (props) => {
  const {t} = useTranslation();
  const location = useLocation()
  const state = location.state as { id: string }
  const navigate = useNavigate();
  const [visible, setVisible] = React.useState(false);
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [selectionData, setSelectionData] = useState<Record<string, any>>([]);
  const [filterParams, setFilterParams] = useState<Record<any, any>>({});
  const columns: GridColDef[] = [
    {field: 'employeeCode', headerName: t("common.Person Id"), type: "string", minWidth: 200},
    {field: 'firstName', headerName: t("common.Person Name"), minWidth: 120},
    {field: 'lastName', headerName: t("common.Person Name"), minWidth: 120}
  ]

  const handleDataGridSave = (value: any) => {
    if (value) {
      setGridData(value)
    }
  }
  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        setVisible(open);
      };

  return (
    <div>
      <React.Fragment>
        <Grid onClick={toggleDrawer(true)}>
          <Tooltip title={t("dms.Add person to device")}>
            <IconButton sx={{borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)"}}>
              <Add/>
            </IconButton>
          </Tooltip>
        </Grid>
        <SwipeableDrawer
          anchor={'right'}
          open={visible}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box sx={{padding: '10px', height: '100%', width: '500px'}}>
            <Box sx={{height: 'calc(100% - 60px)', width: '95%'}}>
              <Grid className={"toolbar-container"}>
                <Box sx={{display: 'flex',}}>
                  <Breadcrumbs separator="" sx={{height: "32px", p: '8px'}}>
                    <Typography>{t("common.Select person")}</Typography>
                  </Breadcrumbs>
                </Box>
              </Grid>
              <Box sx={{padding: "10px 0px"}}>
                <CustomDataGrid
                  uri={`${HREndpoints.HR_EMPLOYEE_NOT_IN_DEVICE}${state.id || ""}`}
                  columns={columns}
                  checkboxSelection={true}
                  sx={customDataGridStyle}
                  onDataChange={handleDataGridSave}
                  onSelectionModelChange={(ids) => {
                    setSelectionModel(ids);
                    setSelectionData(ids.map((id) => gridData.find((row: any) => row.id === id)))
                  }}
                  selectionModel={selectionModel}
                  requestParams={filterParams}
                  toolbar={{
                    title: "",
                    filter: false,
                    refresh: true,
                    searchTips: t("common.Search by Person First Name"),
                  }}
                />
                <Box>
                  <CancelButton
                    sx={{width: '48%', padding: "10px", textTransform: "none"}}
                    onClick={() => {
                      setVisible(false)
                    }}/>
                  <SaveButton
                    children={t("common.confirm")}
                    sx={{width: '48%', padding: "10px", textTransform: "none", marginLeft: "10px"}}
                    onClick={() => {
                      if (selectionModel && selectionModel.length === 0) {
                        SnackbarUtil.error(t("dms.No Person Record Selected"), {
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                          }
                        })
                        return
                      }
                      setLoading(true)
                      DeviceService.addPerson(state.id || '', selectionModel).then(
                        (result) => {
                          console.log('DeviceService.addPerson: ', result)
                          SnackbarUtil.success(t('dms.People synced to the device successfully', {No: selectionModel.length}))
                          navigate(-1)
                        }).catch((error) => {
                        if (error.response.status < 500) {
                          // do something when status is 4xx.
                          console.log('DeviceService.addPerson: ', error)
                        }
                      }).finally(() => {
                        setLoading(false);
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

