import React, {Fragment, useEffect, useState} from "react";
import {Box, Paper, Grid, TextField, Button, IconButton, Stack, Tooltip, DialogTitle, Dialog} from "@mui/material";
import {object, TypeOf, z} from "zod";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {ReactComponent as DepartmentGraph} from "@/assets/images/DepartmentGraph.svg"
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import DepartmentService from "@/services/hr/DepartmentService";
import {useSnackbar} from "notistack";
import WebURL from "@/urls";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import {useTranslation} from "react-i18next";
import {Edit} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";


export function EditAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("common.Edit Department")}>
      <IconButton sx={{borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)"}} onClick={() => {
        setInfo(true)
      }}>
        <Edit/>
      </IconButton>
    </Tooltip>
  )
}

export interface DeptEditProps {
  deptId: string,
}

const DepartmentEdit: React.FC<DeptEditProps> = (props) => {
  const [info, setInfo] = useState<boolean>(false)

  return (
    <Fragment>
      <Grid item={true}>
        <EditAction setInfo={setInfo}/>
      </Grid>
      <EditDeptDialog info={info} setInfo={setInfo} deptId={props.deptId}/>
    </Fragment>
  )
}

const EditDeptDialog = ({info, setInfo, deptId}: any) => {
  const {t} = useTranslation();
  const departmentValidate = object({
    code: z.string().min(1, t("common.This field is required!"))
      .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
    name: z.string().min(1, t("common.This field is required!"))
      .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
  })
  type DepartmentForm = TypeOf<typeof departmentValidate>;
  const {enqueueSnackbar} = useSnackbar();
  const [saving, setSaving] = React.useState<boolean>(false)
  const departmentForm = useForm<DepartmentForm>({
    resolver: zodResolver(departmentValidate),
    defaultValues: {
      code: '',
      name: '',
    }
  });
  useEffect(() => {
    if (deptId) {
      DepartmentService.retrieve(deptId || "").then((res) => {
        departmentForm.setValue("code", res.data.data?.code);
        departmentForm.setValue("name", res.data.data?.name);
      });
    }
  }, []);

  const onSubmit = async (event: any) => {
    setSaving(true)
    try {
      let result;
      if (deptId) {
        // update department
        result = await DepartmentService.update(deptId, departmentForm.getValues());
        if (result.status < 400) {
          enqueueSnackbar(t("common.Department updated successfully."), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
        }
      } else {
        result = await DepartmentService.add(departmentForm.getValues());
        if (result.status < 400) {
          enqueueSnackbar(t("common.Department added successfully."), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
        }
      }

    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
      setInfo(false)
    }
  }

  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      // sx={}
    >
      <DialogTitle>
        {t("common.Edit Department")}
        <IconButton
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <Grid sx={{margin: '10px 0', padding: '10px'}}>
        <Grid
          container
          spacing={2}
        >
          <Grid item>
            <ControlledTextFiled
              hookForm={departmentForm}
              label={t("hr.department.Department name")}
              name="name"
              fullWidth
              required
              placeholder={t("hr.department.Department name")}
            />
            <ControlledTextFiled
              hookForm={departmentForm}
              label={t("hr.department.Department code")}
              name="code"
              fullWidth
              required
              placeholder={t("hr.department.Department code")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{'padding': '20px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end">
          <CancelButton onClick={() => {
            setInfo(false)
          }}/>
          <SavingButton loading={saving} onClick={departmentForm.handleSubmit(onSubmit)}/>
        </Stack>
      </Grid>
    </Dialog>
  )
}

export default DepartmentEdit;
