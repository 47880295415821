import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {DatePicker} from "@mui/x-date-pickers";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Grid, Paper, Stack, TextField, Typography} from "@mui/material";
import {object, TypeOf, z} from "zod";
import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import moment from "moment/moment";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import ScheduleService from "@/services/att/services/ScheduleService";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import {useDispatch, useSelector} from "react-redux";
import {getOrganEmployee} from "@/store/slices/Employee";
import {getOrganDevice} from "@/store/slices/Device";
import TimeSheetSelect from "@/components/scroll-select/TimeSheetSelect";
import {ATTEndpoints} from "../../../../services/att/endpoints";

export type TimeSheetProps = {
  info?: any;
}

const NewScheduleQuick: React.FC<TimeSheetProps> = (props) => {
  const {t} = useTranslation();

  const scheduleValidate = object({
    employee_id:
      z.array(
        z.record(
          z.string(), z.string().nullable()
        ), {required_error: t("common.This field is required!")}
      ),
    timesheet:
      z.record(
        z.string(), z.any(), {required_error: t("common.This field is required!")}
      ),
    device_list:
      z.array(
        z.record(
          z.string(), z.string().nullable()
        ),{}
      ),
    assigned_start:
      z.date(
        {
          errorMap: (issue, {defaultError}) =>
            ({message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError})
        }).or(z.string()),
    assigned_end:
      z.date(
        {
          errorMap: (issue, {defaultError}) =>
            ({message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError})
        }).or(z.string()),
    assigned_type: z.string(),
    overwrite: z.string(),
  })
  type scheduleInput = TypeOf<typeof scheduleValidate>
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const scheduleForm = useForm<scheduleInput>({
    resolver: zodResolver(scheduleValidate),
    defaultValues: {
      assigned_start: today,
      assigned_end: new Date(today.getFullYear(), today.getMonth() + 1, 0),
      assigned_type: "employee",
      overwrite: 'yes',
    }
  })

  const [isLoading, setLoading] = useState<boolean>(false);

  const handleSelectDeviceOpen = () => {
    const employees = scheduleForm.getValues("employee_id")
    if (employees === undefined || employees.length === 0) {
      SnackbarUtil.error(t("att.schedule.Select person first"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      return;
    }
  }
  const employeeValidate = object({
    employeeCode: z.string({required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
      .max(30, t("common.The length of field cannot exceed digits", {number: 30}))
  })
  type employeeInput = TypeOf<typeof employeeValidate>;
  const select_emp: { id: string, employeeCode: string }[] = useSelector((state: any) => {
    return state.emp.employees
  });
  const employeeForm = useForm<employeeInput>({
    resolver: zodResolver(employeeValidate),
  });
  const deviceValidate = object({
    sn: z.string({required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
      .max(30, t("common.The length of field cannot exceed digits", {number: 30}))
  })
  type deviceInput = TypeOf<typeof deviceValidate>;
  const select_device: { id: string, sn: string }[] = useSelector((state: any) => {
    return state.device.devices
  });
  const deviceForm = useForm<deviceInput>({
    resolver: zodResolver(deviceValidate),
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(getOrganEmployee());
    dispatch<any>(getOrganDevice());
  }, [])
  const [timeSheet, setTimeSheet] = React.useState("");
  const [newTimeSheet, setNewTimeSheet] = useState("");
  const handleTimeSheet = (event: any) => {
    if (event !== "notimesheet") {
      setNewTimeSheet("");
      setTimeSheet(event.target.value);
      const timeSheet = event.target.value;
      scheduleForm.setValue("timesheet", {id: timeSheet})
    } else {
      SnackbarUtil.error(t("common.Select Timesheet first"), {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return;
    }
  }

  const onSubmit = async () => {
    const data = scheduleForm.getValues()
    if (data.employee_id === undefined || data.employee_id.length === 0) {
      SnackbarUtil.error(t("att.schedule.Select person first"), {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return;
    }
    if (data.timesheet === undefined || data.timesheet.length === 0) {
      SnackbarUtil.error(t("common.Select Timesheet first"), {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        }
      })
      return;
    }
    data.assigned_start = moment(data.assigned_start).format('yyyy-MM-DD')
    data.assigned_end = moment(data.assigned_end).format('yyyy-MM-DD')
    data.employee_id = data.employee_id.map((emp: any) => emp?.id)
    data.timesheet = data.timesheet?.id
    if (data.device_list !== undefined) {
      data.device_list = data.device_list.map((dev: any) => dev?.id)
    } else {
      data.device_list = []
    }
    setLoading(true)
    ScheduleService.addSchedule(data).then((result) => {
      if (result.status < 400) {
        let msg = t("att.schedule.Successfully save schedule")
        if (data.device_list && data.device_list.length == 1) {
          msg += t("att.schedule.Success synchronize the data of person", {count: data.employee_id?.length})
        } else if (data.device_list && data.device_list.length > 1) {
          msg += t("att.schedule.Success synchronize the data of people", {count: data.employee_id?.length})
        }
        SnackbarUtil.success(msg)
      } else {
        console.log("Failure===, ", result)
        SnackbarUtil.error(t("common.failure"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      }
    }).finally(() => {
      setLoading(false);
      props.info(false)
    });
  };
  return (
    <>
      <Grid style={{minHeight: '200px', backgroundColor: '#fff', borderRadius: '8px', padding: '0 15px 15px 15px'}}>
        <Grid container={true}>
          <Grid container={true} component={"form"} columnSpacing={4} rowSpacing={2}>
            <Grid item xs={12}/>
            <Grid item xs={6}>
              <Typography>
                {t("common.Select person")}
              </Typography>
              <ControlAutoComplete name={"employeeCode"} form={employeeForm} label={''}
                                   options={select_emp}
                                   multi={true}
                                   onChange={(event) => {
                                     scheduleForm.setValue("employee_id", event)
                                   }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {t("common.Select timesheet")}
              </Typography>
              <TimeSheetSelect timeSheet={timeSheet} handleTimeSheet={handleTimeSheet}
                               datafrom={ATTEndpoints.ATT_TIMESHEET_URI} id={"schedule-timesheet-select"}
                               newTimeSheet={newTimeSheet}/>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {t("common.Start Date")}
              </Typography>
              <Controller
                control={scheduleForm.control}
                name="assigned_start"
                render={({field, fieldState}) => (
                  <DatePicker
                    onChange={(date: any) => {
                      if (date > scheduleForm.getValues("assigned_end")) {
                        SnackbarUtil.error(t("export.Start date cannot greater than end date"), {
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                          }
                        })
                        return;
                      }
                      field.onChange(date)
                    }}
                    // inputFormat={"dd-MM-yyyy"}
                    value={field.value || null}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || ""}
                        fullWidth
                      />
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {t("common.End Date")}
              </Typography>
              <Controller
                control={scheduleForm.control}
                name="assigned_end"
                render={({field, fieldState}) => (
                  <DatePicker
                    onChange={(date: any) => {
                      if (date < scheduleForm.getValues("assigned_start")) {
                        SnackbarUtil.error(t("export.End date cannot less than start date"), {
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                          }
                        })
                        return;
                      }
                      field.onChange(date)
                    }}
                    // inputFormat={"dd-MM-yyyy"}
                    value={field.value || null}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || ""} fullWidth
                      />
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {t("att.schedule.Person clock in Device")}
              </Typography>
              <Grid container onClick={handleSelectDeviceOpen}>
                <ControlAutoComplete name={"sn"} form={deviceForm} label={''}
                                     options={select_device}
                                     multi={true}
                                     onChange={(event) => {
                                       const employee_list = scheduleForm.getValues("employee_id")
                                       if (employee_list === undefined || employee_list.length === 0) {
                                         SnackbarUtil.error(t("att.schedule.Select person first"), {
                                           anchorOrigin: {
                                             vertical: 'top',
                                             horizontal: 'center'
                                           }
                                         })
                                         return;
                                       }
                                       scheduleForm.setValue("device_list", event)
                                     }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{marginTop: '100px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end">
          <CancelButton onClick={() => {
            props.info(false)
          }}/>
          <SavingButton loading={isLoading} onClick={onSubmit}/>
        </Stack>
      </Grid>
    </>
  )
}
export default NewScheduleQuick
