import React, { useRef } from "react";
import {GridColDef} from "@mui/x-data-grid";
import {Box, Grid, Stack} from "@mui/material";
import CustomDataGrid, { DataGridRef } from "../../../components/data-grid/CustomDataGrid";
import {HREndpoints} from "../../../services/hr/endpoints";
import { Add, RemoveRedEyeOutlined, DeleteForever } from "@mui/icons-material";
import WebURL from "../../../urls";
import CustomRowButton from "../../../components/data-grid/CustomRowButton";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import EditIcon from "@mui/icons-material/Edit";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Dialog from "@/components/dialog";
import SnackbarUtil from "@/utils/SnackbarUtil";
import EmployeeService from "@/services/hr/EmployeeService";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom"
import DeviceService from '@/services/dms/DeviceService';

const EmployeeDataGrid: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dataGridRef = useRef<DataGridRef>();
  const columns: GridColDef[] = [
    {
      field: 'firstName', headerName: t("common.Person Name"), minWidth: 260, renderCell: (params) => {
        return (
          <Grid style={{display: "flex", alignItems: "center"}}>
            <AvatarIcon name={params.row.firstName} />
            <Box style={{padding: '0 8px'}}>{params.row.firstName} {params.row.lastName}</Box>
          </Grid>
        )
      }
    },
    {field: 'employeeCode', headerName: t("common.Person ID"), type: "string", minWidth: 200},
    // {field: 'email', headerName: t("common.Email"), type: "string", minWidth: 200},
    // {field: 'phone', headerName: t("common.Mobile"), type: "string", minWidth: 180},
    {field: 'department_name', headerName: t("common.Department"), type: "string", minWidth: 180},
    {
      field: 'actions', headerName: t("common.action"), headerAlign: 'center', align: 'center',  flex: 150,
      renderCell: (e) => {
        return (
          <Stack direction={"row"}>
            <CustomRowButton insideIcon={EditIcon} buttonProps={{
              key: "edit",
              onClick: () => {
                navigate(WebURL.HR_EMPLOYEE_VIEW, {state: {id: e.row.id}});
              }
            }}/>
            <CustomRowButton insideIcon={PersonOffIcon} buttonProps={{
              key: "Resigned",
              onClick: async () => {
                Dialog.confirm({
                  width:"650px",
                  title: t("hr.resignation.You sure to resign?", {value: e.row.firstName + " " + e.row.lastName}),
                  helpInfo: t("hr.resignation.Resign Tip"),
                  onConfirm:  () => {
                    EmployeeService.resign(e.row.id).then((response)=>{
                      SnackbarUtil.success(t('common.success'))
                      dataGridRef.current?.refresh();
                    });
                  }});
              }
            }}/>
            <CustomRowButton insideIcon={DeleteForever} buttonProps={{
              key: "delete",
              onClick: async () => {
                Dialog.confirm({
                  title: t("common.You sure to delete?", {value: e.row.firstName + " " + e.row.lastName}),
                  onConfirm:  () => {
                    DeviceService.deleteEmpInDevice({personId:e.row.id}).then((response)=>{
                      if(response.code == "TDMSE1001"){
                        SnackbarUtil.error(t("common.Person in device text"), { anchorOrigin: { vertical: 'top', horizontal: 'center' } })
                      }else if(response.code == "TDMSE1002"){
                        SnackbarUtil.error(t("common.Deleting the person wrong"), { anchorOrigin: { vertical: 'top', horizontal: 'center' } })
                      }else {
                        SnackbarUtil.success(t('common.success'))
                        dataGridRef.current?.refresh();}
                    });
                  }});
                }}}/>
          </Stack>
        )
      }
    }
  ]
  return (
    <CustomDataGrid
      uri={HREndpoints.HR_EMPLOYEE_URI}
      columns={columns}
      ref={dataGridRef}
      checkboxSelection={false}
      toolbar={{
        title: t("common.Person"),
        searchTips: t("common.Search by Person First Name"),
        breadcrumbs: [
          {breadcrumbName: t("common.Person"), path: WebURL.HR_EMPLOYEE}
        ],
        actions: [{
          key: 'add', icon: Add, helpTxt: t("common.add"), callback: async () => {
            navigate(WebURL.HR_EMPLOYEE_NEW);
          }
        }]
      }}

    />
  )
}
export default EmployeeDataGrid
